export default {
  pt: {
    MaintenanceCreate: {
      NewMaintenance: 'Nova Manutenção',
      Save: 'Salvar',

      Client: 'Cliente',
      AddClient: 'Adicionar Cliente',
      EditClient: 'Alterar Cliente',

      DataSource: 'Data Fonte',
      EnterDataSource: 'Informe a data fonte',

      Employee: 'Colaborador',
      AddEmployee: 'Adicionar Colaborador',
      EditEmployee: 'Alterar Colaborador',
      ConfirmEmployee: 'Deseja mesmo adicionar o colaborador?',

      ResponsiblePhone: 'Celular do Responsável',

      Systems: 'Sistemas',

      System: 'Sistema',
      AddSystem: 'Adicionar Sistema',
      EditSystem: 'Alterar Sistema',
      ConfirmSystem: 'Deseja mesmo adicionar o sistema?',

      DeleteComponent: 'Deletar componente',
      Component: 'Componente',
      AddComponent: 'Adicionar Componente',
      Activity: 'Atividade',
      DeleteActivity: 'Deletar atividade',
      AddActivity: 'Adicionar Atividade',

      Periodicity: 'Periodicidade',

      Type: 'Tipo',
      TypePlaceholder: 'Selecione o tipo',

      Daily: 'Diário',
      Weekly: 'Semanal',
      Monthly: 'Mensal',
      Yearly: 'Anual',

      Interval: 'Intervalo',
      EnterInterval: 'Informe o intervalo',

      StartDate: 'Data de Início',
    },
  },
  en: {
    MaintenanceCreate: {
      NewMaintenance: 'New Maintenance',
      Save: 'Save',

      Client: 'Client',
      AddClient: 'Add Client',
      EditClient: 'Edit Client',

      DataSource: 'Data Source',
      EnterDataSource: 'Enter the data source',

      Employee: 'Employee',
      AddEmployee: 'Add Employee',
      EditEmployee: 'Edit Employee',
      ConfirmEmployee: 'Do you really want to add the collaborator?',

      ResponsiblePhone: 'Responsible Phone',

      Systems: 'Systems',

      System: 'System',
      AddSystem: 'Add System',
      EditSystem: 'Edit System',
      ConfirmSystem: 'Do you really want to add the system?',

      DeleteComponent: 'Delete component',
      Component: 'Component',
      AddComponent: 'Add Component',
      Activity: 'Activity',
      DeleteActivity: 'Delete activity',
      AddActivity: 'Add Activity',

      Periodicity: 'Periodicity',

      Type: 'Type',
      TypePlaceholder: 'Select the type',

      Daily: 'Daily',
      Weekly: 'Weekly',
      Monthly: 'Monthly',
      Yearly: 'Yearly',

      Interval: 'Interval',
      EnterInterval: 'Enter the interval',

      StartDate: 'Start Date',
    },
  },
  es: {
    MaintenanceCreate: {
      NewMaintenance: 'Nueva Mantenimiento',
      Save: 'Guardar',

      Client: 'Cliente',
      AddClient: 'Agregar Cliente',
      EditClient: 'Modificar Cliente',

      DataSource: 'Fecha Fuente',
      EnterDataSource: 'Informe la fecha fuente',

      Employee: 'Colaborador',
      AddEmployee: 'Agregar Colaborador',
      EditEmployee: 'Modificar Colaborador',
      ConfirmEmployee: '¿Realmente quieres agregar al colaborador?',

      ResponsiblePhone: 'Teléfono del Responsable',

      Systems: 'Sistemas',

      System: 'Sistema',
      AddSystem: 'Agregar Sistema',
      EditSystem: 'Modificar Sistema',
      ConfirmSystem: '¿Realmente desea agregar el sistema?',

      DeleteComponent: 'Eliminar componente',
      Component: 'Componente',
      AddComponent: 'Agregar Componente',
      Activity: 'Actividad',
      DeleteActivity: 'Eliminar actividad',
      AddActivity: 'Agregar Actividad',

      Periodicity: 'Periodicidad',

      Type: 'Tipo',
      TypePlaceholder: 'Seleccionar tipo',

      Daily: 'Diario',
      Weekly: 'Semanal',
      Monthly: 'Mensual',
      Yearly: 'Anual',

      Interval: 'Intervalo',
      EnterInterval: 'Informe el intervalo',

      StartDate: 'Fecha de Inicio',
    },
  },
}
