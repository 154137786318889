export default {
  pt: {
    MaintenanceSystemTab: {
      NewSystem: 'Novo Sistema',
      AddSystem: 'Adicionar Sistema',
      DeleteSystem: 'Deletar Sistema',

      SystemName: 'Nome do Sistema:',
      Periodicity: 'Periodicidade',
      PeriodicityStartDate: 'Data de Início da Periodicidade:',

      Days: 'dias',
      Day: 'dia',
      Weeks: 'semanas',
      Week: 'semana',
      Months: 'mês',
      MonthsPlural: 'meses',
      Year: 'ano',
      Years: 'anos',

      ComponentsElements: 'Componentes/Elementos',
      AddComponent: 'Adicionar componente',
      DeleteComponent: 'Deletar componente',
      EditComponent: 'Editar componente',

      Activities: 'Atividades',
      AddActivity: 'Adicionar atividade',
      DeleteActivity: 'Deletar atividade',
      EditActivity: 'Editar atividade',

      ComponentConfirm: 'Deseja mesmo deletar o componente?',
      ActivityConfirm: 'Deseja mesmo deletar a atividade?',
      SystemConfirm: 'Deseja mesmo deletar o sistema?',

      AddComponentToSystem: 'Adicionar componente/elemento ao sistema',
      ComponentElement: 'Componente/Elemento',
      EnterComponentElement: 'Informe o componente/elemento',

      Text: 'A cada',

      Name: 'Nome',
      AddActivityToSystem: 'Adicionar atividade ao sistema',
      Activity: 'Atividade',
      EnterActivity: 'Informe a atividade',
    },
  },
  en: {
    MaintenanceSystemTab: {
      NewSystem: 'New System',
      AddSystem: 'Add System',
      DeleteSystem: 'Delete System',

      SystemName: 'System Name:',
      Periodicity: 'Periodicity',
      PeriodicityStartDate: 'Periodicity Start Date:',

      Days: 'days',
      Day: 'day',
      Weeks: 'weeks',
      Week: 'week',
      Months: 'month',
      MonthsPlural: 'months',
      Year: 'year',
      Years: 'years',

      ComponentsElements: 'Components/Elements',
      AddComponent: 'Add component',
      DeleteComponent: 'Delete component',
      EditComponent: 'Edit component',

      Activities: 'Activities',
      AddActivity: 'Add activity',
      DeleteActivity: 'Delete activity',
      EditActivity: 'Edit activity',

      ComponentConfirm: 'Do you really want to delete the component?',
      ActivityConfirm: 'Do you really want to delete the activity?',
      SystemConfirm: 'Do you really want to delete the system?',

      AddComponentToSystem: 'Add component/element to the system',
      ComponentElement: 'Component/Element',
      EnterComponentElement: 'Enter the component/element',

      Text: 'Every',

      Name: 'Name',
      AddActivityToSystem: 'Add activity to the system',
      Activity: 'Activity',
      EnterActivity: 'Enter the activity',
    },
  },
  es: {
    MaintenanceSystemTab: {
      NewSystem: 'Nuevo Sistema',
      AddSystem: 'Agregar Sistema',
      DeleteSystem: 'Eliminar Sistema',

      SystemName: 'Nombre del Sistema:',
      Periodicity: 'Periodicidad',
      PeriodicityStartDate: 'Fecha de Inicio de la Periodicidad:',

      Days: 'días',
      Day: 'día',
      Weeks: 'semanas',
      Week: 'semana',
      Months: 'mes',
      MonthsPlural: 'meses',
      Year: 'año',
      Years: 'años',

      ComponentsElements: 'Componentes/Elementos',
      AddComponent: 'Agregar componente',
      DeleteComponent: 'Eliminar componente',
      EditComponent: 'Editar componente',

      Activities: 'Actividades',
      AddActivity: 'Agregar actividad',
      DeleteActivity: 'Eliminar actividad',
      EditActivity: 'Editar actividad',

      ComponentConfirm: '¿Realmente desea eliminar el componente?',
      ActivityConfirm: '¿Realmente deseas eliminar la actividad?',
      SystemConfirm: '¿Realmente quieres eliminar el sistema?',

      AddComponentToSystem: 'Agregar componente/elemento al sistema',
      ComponentElement: 'Componente/Elemento',
      EnterComponentElement: 'Informe el componente/elemento',

      Text: 'Cada',

      Name: 'Nombre',
      AddActivityToSystem: 'Agregar actividad al sistema',
      Activity: 'Actividad',
      EnterActivity: 'Informe la actividad',
    },
  },
}
