import React from 'react'
import { Switch } from 'react-router-dom'

import Route from './Route'

import SignIn from '~/pages/Auth/SignIn'
import SignUp from '~/pages/Auth/SignUp'
import ForgotPassword from '~/pages/Auth/ForgotPassword'
import ResetPassword from '~/pages/Auth/ResetPassword'
import ConfirmCode from '~/pages/Auth/ConfirmCode'

import UserAccess from '~/pages/UserAccess'

import Dashboard from '~/pages/Dashboard'
import DashboardTasks from '~/pages/Dashboard/Tasks'
import DashboardContributors from '~/pages/Dashboard/Contributors'
import DashboardCustomers from '~/pages/Dashboard/Customers'

import Contributors from '~/pages/Contributor/Contributors'
import NewContributor from '~/pages/Contributor/NewContributor'
import ShowContributor from '~/pages/Contributor/ShowContributor'

import Users from '~/pages/Users'
import ShowUsers from '~/pages/Users/ShowUsers'

import Status from '~/pages/Status'
import ShowStatus from '~/pages/Status/ShowStatus'

import Scales from '~/pages/Scale/Scales'
import EditScale from '~/pages/Scale/EditScale'
import Skills from '~/pages/Skills'

import Segments from '~/pages/Segments'

import Clients from '~/pages/Clients'
import NewClients from '~/pages/Clients/NewClients'
import ShowClients from '~/pages/Clients/ShowClients'
import ShowContactsClients from '~/pages/Clients/ShowContacts'
import NewContacts from '~/pages/Clients/NewContacts'

import Equipments from '~/pages/Equipments'
import NewEquipments from '~/pages/Equipments/NewEquipments'
import ShowEquipments from '~/pages/Equipments/ShowEquipments'
import ShowCategory from '~/pages/Equipments/ShowCategory'
import ShowTemplates from '~/pages/Equipments/ShowTemplates'

import TaskTypes from '~/pages/TasksRegistration/TaskType'
import NewTaskTypes from '~/pages/TasksRegistration/TaskType/NewTaskType'

import Pause from '~/pages/TasksRegistration/Pause'

import Forms from '~/pages/TasksRegistration/Forms'
import NewForm from '~/pages/TasksRegistration/Forms/NewForm'

import Tasks from '~/pages/Tasks'
import NewTasks from '~/pages/Tasks/NewTasks'
import ShowTasks from '~/pages/Tasks/ShowTasks'
import ListTasks from '~/pages/Tasks/ListTasks'
import KanbanTasks from '~/pages/Tasks/KanbanTasks'

import Groups from '~/pages/Groups'
import ShowGroups from '~/pages/Groups/ShowGroups'

import Teams from '~/pages/Teams'
import ShowTeams from '~/pages/Teams/ShowTeams'

import ReportTasks from '~/pages/Report/Tasks'
import ReportKilometers from '~/pages/Report/Kilometers'

import PageFaq from '~/pages/PageFaq'

import HelpDesk from '~/pages/HelpDesk'
import NewTicket from '~/pages/HelpDesk/NewTicket'
import ShowTicket from '~/pages/HelpDesk/ShowTicket'
import KanbanTicket from '~/pages/HelpDesk/KanbanTicket'
import TicketType from '~/pages/HelpDesk/TicketType'
import NewTicketClient from '~/pages/HelpDesk/NewTicket/NewTicketClient'

import RedirectPanel from '~/pages/RedirectPanel'

import Payment from '~/pages/Payment'
import ShowPayment from '~/pages/Payment/Show'

import Badge from '~/pages/Badge'
import NewBadge from '~/pages/Badge/NewBadge'
import AboutForm from '~/components/DataManipulation/TasksRegistration/Forms/About'
import ParentCustomers from '~/pages/Clients/ParentCustomers'
import NewParentClients from '~/pages/Clients/ParentCustomers/NewParentClients'
import ShowParentCustomers from '~/pages/Clients/ParentCustomers/ShowParentClients'

import FinancialCustomersPage from '~/pages/Financial/Customers'
import FinancialTeamsPage from '~/pages/Financial/Teams'

import FinancialPage from '~/pages/Financial'
import TestingCreateTask from '~/pages/Tasks/Testing'
import SystemMaintenance from '~/pages/Maintenance/System'
import Maintenance from '~/pages/Maintenance'
import CreateMaintenance from '~/pages/Maintenance/Create'
import SystemCreate from '~/pages/Maintenance/System/Create'
import ShowMaintenance from '~/pages/Maintenance/Show'

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route path="/" exact component={SignIn} />
      <Route path="/signup" exact component={SignUp} />
      <Route path="/forgot-password" exact component={ForgotPassword} />
      <Route path="/reset-password/:token?" exact component={ResetPassword} />
      <Route
        path="/noaccess"
        exact
        component={UserAccess}
        UserAccess
        isPrivate
      />
      <Route path="/dashboard" exact component={Dashboard} isPrivate />
      <Route
        path="/dashboardTasks"
        exact
        component={DashboardTasks}
        isPrivate
      />
      <Route
        path="/dashboardContributors"
        exact
        component={DashboardContributors}
        isPrivate
      />
      <Route
        path="/dashboardCustomers"
        exact
        component={DashboardCustomers}
        isPrivate
      />
      <Route path="/colaboradores" exact component={Contributors} isPrivate />
      <Route
        path="/colaboradores/novo"
        exact
        component={NewContributor}
        isPrivate
      />
      <Route
        path="/colaboradores/visualizar/colaborador/:contributorId/contato/:contactId/:tab?"
        exact
        component={ShowContributor}
        isPrivate
      />

      <Route path="/usuarios" exact component={Users} isPrivate />
      <Route
        path="/usuarios/visualizar/:userId"
        exact
        component={ShowUsers}
        isPrivate
      />

      <Route path="/status" exact component={Status} isPrivate />
      <Route
        path="/status/visualizar/:statusId"
        exact
        component={ShowStatus}
        isPrivate
      />

      <Route path="/escalas" exact component={Scales} isPrivate />
      <Route
        path="/escalas/editar/:scaleId/:tab?"
        exact
        component={EditScale}
        isPrivate
      />
      <Route path="/habilidades" exact component={Skills} isPrivate />
      <Route
        path="/confirm-user/:token?"
        exact
        component={ConfirmCode}
        isConfirmation
        isPrivate
      />

      <Route path="/segmentos" exact component={Segments} isPrivate />

      <Route path="/clientes" exact component={Clients} isPrivate />
      <Route path="/clientes/novo" exact component={NewClients} isPrivate />
      <Route
        path="/clientes/visualizar/:customerId/contato/:contactId/:tab?"
        exact
        component={ShowClients}
        isPrivate
      />
      <Route
        path="/contatosCliente"
        exact
        component={ShowContactsClients}
        isPrivate
      />

      <Route
        path="/contatosCliente/novo"
        exact
        component={NewContacts}
        isPrivate
      />

      <Route
        path="/clientePrincipal"
        exact
        component={ParentCustomers}
        isPrivate
      />
      <Route
        path="/clientePrincipal/novo"
        exact
        component={NewParentClients}
        isPrivate
      />
      <Route
        path="/clientePrincipal/visualizar/:parentCustomerId/"
        exact
        component={ShowParentCustomers}
        isPrivate
      />

      <Route path="/equipamentos" exact component={Equipments} isPrivate />
      <Route
        path="/equipamentos/novo"
        exact
        component={NewEquipments}
        isPrivate
      />
      <Route path="/categorias" exact component={ShowCategory} isPrivate />
      <Route path="/templates" exact component={ShowTemplates} isPrivate />

      <Route
        path="/equipamentos/visualizar/:equipmentId/equipamento/"
        exact
        component={ShowEquipments}
        isPrivate
      />

      <Route path="/tiposDeTarefas" exact component={TaskTypes} isPrivate />
      <Route
        path="/tiposDeTarefas/novo"
        exact
        component={NewTaskTypes}
        isPrivate
      />

      <Route path="/motivosDePausa" exact component={Pause} isPrivate />

      <Route path="/formularios" exact component={Forms} isPrivate />
      <Route path="/formularios/novo" exact component={NewForm} isPrivate />
      <Route
        path="/formularios/:formId"
        exact
        component={AboutForm}
        isPrivate
      />

      <Route path="/agenda" exact component={Tasks} isPrivate />
      <Route path="/tarefas" exact component={ListTasks} isPrivate />
      <Route path="/kanban" exact component={KanbanTasks} isPrivate />
      <Route path="/novaTarefa" exact component={NewTasks} isPrivate />
      <Route
        path="/novaTarefaTeste"
        exact
        component={TestingCreateTask}
        isPrivate
      />
      <Route
        path="/tarefas/visualizar/:taskId/:tab?"
        exact
        component={ShowTasks}
        isPrivate
      />

      <Route path="/permissoes" exact component={Groups} isPrivate />
      <Route
        path="/permissoes/visualizar/:groupId"
        exact
        component={ShowGroups}
        isPrivate
      />

      <Route path="/equipes" exact component={Teams} isPrivate />
      <Route
        path="/equipes/visualizar/:teamId"
        exact
        component={ShowTeams}
        isPrivate
      />

      <Route path="/relatorioTarefas" exact component={ReportTasks} isPrivate />
      <Route
        path="/relatorioQuilometros"
        exact
        component={ReportKilometers}
        isPrivate
      />
      <Route path="/ajuda" exact component={PageFaq} isPrivate />

      <Route path="/tiposSolicitacao" exact component={TicketType} isPrivate />
      <Route path="/helpdesk" exact component={HelpDesk} isPrivate />
      <Route path="/novoTicket" exact component={NewTicket} isPrivate />
      <Route
        path="/novoTicket/:companyId/client/:clientId/:emailId/:phoneId/:companyName"
        exact
        component={NewTicketClient}
      />
      <Route path="/ticket/:ticketId" exact component={ShowTicket} isPrivate />
      <Route path="/kanbanTickets" exact component={KanbanTicket} isPrivate />

      <Route path="/auth/panel/:email" exact component={RedirectPanel} />

      <Route path="/pagamento" exact component={Payment} isPrivate />
      <Route
        path="/pagamento/visualizar"
        exact
        component={ShowPayment}
        isPrivate
      />

      <Route path="/cracha" exact component={Badge} isPrivate />
      <Route path="/cracha/novo" exact component={NewBadge} isPrivate />

      <Route
        path="/financeiro/valoresReceber"
        exact
        component={FinancialCustomersPage}
        isPrivate
      />
      <Route
        path="/financeiro/valoresPagar"
        exact
        component={FinancialTeamsPage}
        isPrivate
      />

      <Route
        path="/financeiro/valoresAcordados"
        exact
        component={FinancialPage}
        isPrivate
      />

      <Route
        path="/manutencao/sistemas"
        exact
        component={SystemMaintenance}
        isPrivate
      />
      <Route
        path="/manutencao/sistemas/novo"
        exact
        component={SystemCreate}
        isPrivate
      />

      <Route path="/manutencao" exact component={Maintenance} isPrivate />
      <Route
        path="/manutencao/novo"
        exact
        component={CreateMaintenance}
        isPrivate
      />
      <Route
        path="/manutencao/visualizar/:maintenanceId/:tab?"
        exact
        component={ShowMaintenance}
        isPrivate
      />
    </Switch>
  )
}

export default Routes
