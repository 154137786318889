import React from 'react'
import MaintenanceBoard from '~/components/DataManipulation/Maintenance'
import { ListMaintenanceProvider } from '~/hooks/Maintenance/useMaintenance'

// import { Container } from './styles';

const Maintenance: React.FC = () => {
  return (
    <ListMaintenanceProvider>
      <MaintenanceBoard />
    </ListMaintenanceProvider>
  )
}

export default Maintenance
