export default {
  pt: {
    useMaintenance: {
      MaintenanceCreatedSuccess: 'Manutenção criada com sucesso!',
      MaintenanceCreatedError: 'Houve um erro ao criar a manutenção.',

      MaintenanceUpdatedSuccess: 'Manutenção atualizada com sucesso!',
      MaintenanceUpdatedError: 'Houve um erro ao atualizar a manutenção.',

      MaintenanceDeletedSuccess: 'Manutenção deletada com sucesso!',
      MaintenanceDeletedError: 'Houve um erro ao deletar a manutenção.',

      ComponentAddedSuccess: 'Componente adicionado com sucesso!',
      ComponentAddedError: 'Houve um erro ao adicionar o componente.',

      ComponentUpdatedSuccess: 'Componente atualizado com sucesso!',
      ComponentUpdatedError: 'Houve um erro ao atualizar o componente.',

      ComponentDeletedSuccess: 'Componente deletado com sucesso!',
      ComponentDeletedError: 'Houve um erro ao deletar o componente.',

      ActivityAddedSuccess: 'Atividade adicionada com sucesso!',
      ActivityAddedError: 'Houve um erro ao adicionar a atividade.',

      ActivityUpdatedSuccess: 'Atividade atualizada com sucesso!',
      ActivityUpdatedError: 'Houve um erro ao atualizar a atividade.',

      ActivityDeletedSuccess: 'Atividade deletada com sucesso!',
      ActivityDeletedError: 'Houve um erro ao deletar a atividade.',

      SystemCreatedSuccess: 'Sistema criado com sucesso!',
      SystemCreatedError: 'Houve um erro ao criar o sistema.',

      SystemDeletedSuccess: 'Sistema deletado com sucesso!',
      SystemDeletedError: 'Houve um erro ao deletar o sistema.',
    },
  },
  en: {
    useMaintenance: {
      MaintenanceCreatedSuccess: 'Maintenance created successfully!',
      MaintenanceCreatedError: 'There was an error creating the maintenance.',

      MaintenanceUpdatedSuccess: 'Maintenance updated successfully!',
      MaintenanceUpdatedError: 'There was an error updating the maintenance.',

      MaintenanceDeletedSuccess: 'Maintenance deleted successfully!',
      MaintenanceDeletedError: 'There was an error deleting the maintenance.',

      ComponentAddedSuccess: 'Component added successfully!',
      ComponentAddedError: 'There was an error adding the component.',

      ComponentUpdatedSuccess: 'Component updated successfully!',
      ComponentUpdatedError: 'There was an error updating the component.',

      ComponentDeletedSuccess: 'Component deleted successfully!',
      ComponentDeletedError: 'There was an error deleting the component.',

      ActivityAddedSuccess: 'Activity added successfully!',
      ActivityAddedError: 'There was an error adding the activity.',

      ActivityUpdatedSuccess: 'Activity updated successfully!',
      ActivityUpdatedError: 'There was an error updating the activity.',

      ActivityDeletedSuccess: 'Activity deleted successfully!',
      ActivityDeletedError: 'There was an error deleting the activity.',

      SystemCreatedSuccess: 'System created successfully!',
      SystemCreatedError: 'There was an error creating the system.',

      SystemDeletedSuccess: 'System deleted successfully!',
      SystemDeletedError: 'There was an error deleting the system.',
    },
  },
  es: {
    useMaintenance: {
      MaintenanceCreatedSuccess: '¡Mantenimiento creado con éxito!',
      MaintenanceCreatedError: 'Hubo un error al crear el mantenimiento.',

      MaintenanceUpdatedSuccess: '¡Mantenimiento actualizada con éxito!',
      MaintenanceUpdatedError: 'Hubo un error al actualizar el mantenimiento.',

      MaintenanceDeletedSuccess: '¡Mantenimiento eliminado con éxito!',
      MaintenanceDeletedError: 'Hubo un error al eliminar el mantenimiento.',

      ComponentAddedSuccess: '¡Componente agregado con éxito!',
      ComponentAddedError: 'Hubo un error al agregar el componente.',

      ComponentUpdatedSuccess: '¡Componente actualizado con éxito!',
      ComponentUpdatedError: 'Hubo un error al actualizar el componente.',

      ComponentDeletedSuccess: '¡Componente eliminado con éxito!',
      ComponentDeletedError: 'Hubo un error al eliminar el componente.',

      ActivityAddedSuccess: '¡Actividad agregada con éxito!',
      ActivityAddedError: 'Hubo un error al agregar la actividad.',

      ActivityUpdatedSuccess: '¡Actividad actualizada con éxito!',
      ActivityUpdatedError: 'Hubo un error al actualizar la actividad.',

      ActivityDeletedSuccess: '¡Actividad eliminada con éxito!',
      ActivityDeletedError: 'Hubo un error al eliminar la actividad.',

      SystemCreatedSuccess: '¡Sistema creado con éxito!',
      SystemCreatedError: 'Hubo un error al crear el sistema.',

      SystemDeletedSuccess: '¡Sistema eliminado con éxito!',
      SystemDeletedError: 'Hubo un error al eliminar el sistema.',
    },
  },
}
