/* eslint-disable @typescript-eslint/no-explicit-any */
import { PlusOutlined } from '@ant-design/icons'
import { Button, Form, Input, Modal, Tooltip, message } from 'antd'
import React, { useState } from 'react'
import { useShowMaintenance } from '~/hooks/Maintenance/useShowMaintenance'
import { translate } from '~/utils/locale'

// import { Container } from './styles';

interface SystemData {
  activity: string
}

interface SystemProps {
  id: string
}

const AddActivity: React.FC<SystemProps> = ({ id }) => {
  const { addActivity } = useShowMaintenance()
  const [isModalVisible, setIsModalVisible] = useState(false)

  const showModal = () => {
    setIsModalVisible(true)
  }

  const closeModal = () => {
    setIsModalVisible(false)
  }

  const AddComponent = (data: SystemData) => {
    try {
      const component = {
        activity: data.activity,
      }

      addActivity(component, id)
      setIsModalVisible(false)
    } catch (err: any) {
      message.error('Error!')
    }
  }

  return (
    <>
      <Tooltip title={translate('MaintenanceSystemTab.AddActivity')}>
        <Button
          shape="circle"
          ghost
          size="small"
          onClick={showModal}
          type="primary"
          icon={<PlusOutlined />}
        />
      </Tooltip>
      <Modal
        title={translate('MaintenanceSystemTab.AddActivityToSystem')}
        open={isModalVisible}
        onOk={closeModal}
        onCancel={closeModal}
        footer={[
          <Button key="back" onClick={closeModal}>
            {translate('TasksRegistrationPauseUpdate.closeButton')}
          </Button>,
          <Button
            type="primary"
            key="update"
            htmlType="submit"
            form="sytemAddActivityForm"
          >
            {translate('TasksRegistrationPauseUpdate.saveButton')}
          </Button>,
        ]}
      >
        <Form
          layout="vertical"
          onFinish={AddComponent}
          id="sytemAddActivityForm"
        >
          <Form.Item
            label={translate('MaintenanceSystemTab.Activity')}
            name="activity"
          >
            <Input.TextArea
              style={{ width: '100%' }}
              placeholder={translate('MaintenanceSystemTab.EnterActivity')}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default AddActivity
