/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, createContext, useState } from 'react'
import { useQuery } from 'react-query'

import { message } from 'antd'

import api from '~/services/api'

import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useLocale } from '~/hooks/locale/useLocale'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'
import { useParams } from 'react-router-dom'

interface MaintenanceShowProps {
  _id: string
  company_id: string
  customer?: string
  data_font?: string
  responsible: {
    name: string
    phone: string
  }
  systems: [
    {
      _id: string
      name: string
      components?: [{ _id: string; name: string }]
      activities: [{ _id: string; activity: string }]
      periodicity: {
        type: string
        interval: number
        date: Date
      }
    },
  ]
}

interface MaintenanceShowContextData {
  maintenance?: MaintenanceShowProps
  updateMaintenance(valueForm: object | undefined): void
  addComponent(valueForm: object | undefined, systemId?: string): void
  updateComponent(
    valueForm: object | undefined,
    systemId?: string,
    componentId?: string,
  ): void
  deleteComponent(systemId?: string, componentId?: string): void
  addActivity(valueForm: object | undefined, activityId?: string): void
  updateActivity(
    valueForm: object | undefined,
    systemId?: string,
    activityId?: string,
  ): void
  deleteActivity(systemId?: string, activityId?: string): void
  addSystem(valueForm: object | undefined): void
  deleteSystem(systemId?: string): void
  isLoading: boolean
  refetch: () => void
  isFetching: boolean
  setIsEdit(value: boolean): void
  isEdit?: boolean
}

const MaintenanceShowContext = createContext<MaintenanceShowContextData>(
  {} as MaintenanceShowContextData,
)

export const MaintenanceShowProvider: React.FC = ({ children }) => {
  const { maintenanceId } = useParams<{ maintenanceId: string }>()

  const [isEdit, setIsEdit] = useState<boolean>()

  const { selectedCompany } = useCompanies()
  const { locale } = useLocale()

  const {
    data: maintenance,
    isLoading,
    refetch,
    isFetching,
  } = useQuery(
    [`sistemas/gestaoShow${selectedCompany}`, selectedCompany],

    async () => {
      try {
        const response = await api.get(
          `/company/${selectedCompany}/maintenance/${maintenanceId}`,
        )

        const { data } = response

        return data
      } catch (err: any) {
        ShowError(err.message, translate('useSystem.getErrorMessage'), locale)
      }
    },
  )

  async function updateMaintenance(valueForm: object) {
    try {
      await api.put(
        `/company/${selectedCompany}/maintenance/${maintenanceId}`,
        valueForm,
      )

      message.success(translate('useMaintenance.MaintenanceUpdatedSuccess'))
      refetch()
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useMaintenance.MaintenanceUpdatedError'),
        locale,
      )
    }
  }

  async function addComponent(form?: object, systemId?: string) {
    try {
      await api.post(
        `/company/${selectedCompany}/maintenance/${maintenanceId}/system/${systemId}/component`,
        { component: form },
      )

      refetch()
      message.success(translate('useMaintenance.ComponentAddedSuccess'))
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useMaintenance.ComponentAddedError'),
        locale,
      )
    }
  }

  async function updateComponent(
    form?: object,
    systemId?: string,
    componentId?: string,
  ) {
    try {
      await api.put(
        `/company/${selectedCompany}/maintenance/${maintenanceId}/system/${systemId}/component/${componentId}`,
        { updatedComponent: form },
      )

      refetch()
      message.success(translate('useMaintenance.ComponentUpdatedSuccess'))
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useMaintenance.ComponentUpdatedError'),
        locale,
      )
    }
  }

  async function deleteComponent(systemId?: string, componentId?: string) {
    try {
      await api.delete(
        `/company/${selectedCompany}/maintenance/${maintenanceId}/system/${systemId}/component/${componentId}`,
      )

      refetch()
      message.success(translate('useMaintenance.ComponentDeletedSuccess'))
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useMaintenance.ComponentDeletedError'),
        locale,
      )
    }
  }

  async function addActivity(form?: object, activityId?: string) {
    try {
      await api.post(
        `/company/${selectedCompany}/maintenance/${maintenanceId}/system/${activityId}/activity`,
        { component: form },
      )

      refetch()
      message.success(translate('useMaintenance.ActivityAddedSuccess'))
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useMaintenance.ActivityAddedError'),
        locale,
      )
    }
  }

  async function updateActivity(
    form?: object,
    systemId?: string,
    activityId?: string,
  ) {
    try {
      await api.put(
        `/company/${selectedCompany}/maintenance/${maintenanceId}/system/${systemId}/activity/${activityId}`,
        { updatedActivity: form },
      )

      refetch()
      message.success(translate('useMaintenance.ActivityUpdatedSuccess'))
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useMaintenance.ActivityUpdatedError'),
        locale,
      )
    }
  }

  async function deleteActivity(systemId?: string, activityId?: string) {
    try {
      await api.delete(
        `/company/${selectedCompany}/maintenance/${maintenanceId}/system/${systemId}/activity/${activityId}`,
      )

      refetch()
      message.success(translate('useMaintenance.ActivityDeletedSuccess'))
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useMaintenance.ActivityDeletedError'),
        locale,
      )
    }
  }

  async function addSystem(form?: object) {
    try {
      await api.post(
        `/company/${selectedCompany}/maintenance/${maintenanceId}/system`,
        { system: form },
      )

      refetch()
      message.success(translate('useMaintenance.SystemCreatedSuccess'))
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useMaintenance.SystemCreatedError'),
        locale,
      )
    }
  }

  async function deleteSystem(systemId?: string) {
    try {
      await api.delete(
        `/company/${selectedCompany}/maintenance/${maintenanceId}/system/${systemId}`,
      )

      refetch()
      message.success(translate('useMaintenance.SystemDeletedSuccess'))
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useMaintenance.SystemDeletedError'),
        locale,
      )
    }
  }

  return (
    <MaintenanceShowContext.Provider
      value={{
        maintenance,
        updateMaintenance,
        addComponent,
        addActivity,
        addSystem,
        updateActivity,
        updateComponent,
        deleteActivity,
        deleteComponent,
        deleteSystem,
        isLoading,
        refetch,
        isFetching,
        isEdit,
        setIsEdit,
      }}
    >
      {children}
    </MaintenanceShowContext.Provider>
  )
}

export function useShowMaintenance(): MaintenanceShowContextData {
  const context = useContext(MaintenanceShowContext)

  if (!context) {
    throw new Error('useAuth must be used within an AutoProvider')
  }

  return context
}
