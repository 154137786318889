/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, createContext, useState } from 'react'
import { useQuery } from 'react-query'

import { message } from 'antd'

import api from '~/services/api'

import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useLocale } from '~/hooks/locale/useLocale'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'
import { useHistory } from 'react-router-dom'

interface MaintenanceProps {
  _id: string
  company_id: string
  customer?: string
  data_font?: string
  responsible: {
    name: string
    phone: string
  }
  systems: [
    {
      components?: [{ component: string }]
      activities: [{ activity: string }]
      periodicity: {
        type: string
        interval: number
        date: Date
      }
    },
  ]
}

interface DataProps {
  id?: string
  name: string
  phone?: string
  components?: [{ name: string }]
  activities?: [{ activity: string }]
  type?: string
  interval?: number
  date?: Date
}

interface MaintenanceContextData {
  maintenance?: MaintenanceProps[]
  createMaintenance(valueForm: object | undefined): void
  updateMaintenance(valueForm: object | undefined, maintenanceId: string): void
  deleteMaintenance(maintenanceId: string): void
  clientData?: DataProps
  setClientData(value: DataProps): void
  contributorData?: DataProps
  setContributorData(value: DataProps): void
  systemData?: DataProps
  setSystemData(value: DataProps): void
  isLoading: boolean
  refetch: () => void
  isFetching: boolean
  maintenanceTotal?: number
}

const MaintenanceContext = createContext<MaintenanceContextData>(
  {} as MaintenanceContextData,
)

export const ListMaintenanceProvider: React.FC = ({ children }) => {
  const { selectedCompany } = useCompanies()
  const { locale } = useLocale()

  const history = useHistory()

  const [maintenanceTotal, setMaintenanceTotal] = useState<number>()

  const [clientData, setClientData] = useState<DataProps>({} as DataProps)
  const [contributorData, setContributorData] = useState<DataProps>(
    {} as DataProps,
  )

  const [systemData, setSystemData] = useState<DataProps>({} as DataProps)

  const {
    data: maintenance,
    isLoading,
    refetch,
    isFetching,
  } = useQuery(
    [`sistemas/gestao${selectedCompany}`, selectedCompany, maintenanceTotal],

    async () => {
      try {
        const response = await api.get(
          `/company/${selectedCompany}/maintenances`,
        )

        const { data } = response

        setMaintenanceTotal(data.total)

        return data.results
      } catch (err: any) {
        ShowError(err.message, translate('useSystem.getErrorMessage'), locale)
      }
    },
  )

  async function createMaintenance(valueForm: object) {
    try {
      await api.post(`/company/${selectedCompany}/maintenance`, valueForm)

      refetch()
      message.success(translate('useMaintenance.MaintenanceCreatedSuccess'))

      history.push('/manutencao')
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useMaintenance.MaintenanceCreatedError'),
        locale,
      )
    }
  }

  async function updateMaintenance(valueForm: object, maintenanceId: string) {
    try {
      await api.put(
        `/company/${selectedCompany}/maintenance/${maintenanceId}`,
        valueForm,
      )

      message.success(translate('useMaintenance.updateSuccessMessage'))
      refetch()
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useMaintenance.updateErrorMessage'),
        locale,
      )
    }
  }

  async function deleteMaintenance(maintenanceId: string) {
    try {
      await api.delete(
        `/company/${selectedCompany}/maintenance/${maintenanceId}`,
      )

      refetch()
      message.success(translate('useMaintenance.MaintenanceDeletedSuccess'))
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useMaintenance.MaintenanceDeletedError'),
        locale,
      )
    }
  }

  return (
    <MaintenanceContext.Provider
      value={{
        maintenance,
        createMaintenance,
        updateMaintenance,
        deleteMaintenance,
        isLoading,
        refetch,
        isFetching,
        maintenanceTotal,
        clientData,
        contributorData,
        setClientData,
        setContributorData,
        systemData,
        setSystemData,
      }}
    >
      {children}
    </MaintenanceContext.Provider>
  )
}

export function useMaintenance(): MaintenanceContextData {
  const context = useContext(MaintenanceContext)

  if (!context) {
    throw new Error('useAuth must be used within an AutoProvider')
  }

  return context
}
