/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ArrowLeftOutlined,
  DeleteOutlined,
  PlusOutlined,
  SaveOutlined,
} from '@ant-design/icons'
import {
  Button,
  Form,
  Input,
  Row,
  Tooltip,
  Col,
  Card,
  Space,
  Divider,
  Select,
  InputNumber,
  DatePicker,
} from 'antd'
import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { useSystem } from '~/hooks/Maintenance/System/useSystem'
import { translate } from '~/utils/locale'

const CreateSystem: React.FC = () => {
  const { createSystem } = useSystem()

  const [form] = Form.useForm()

  function onCreateSystem(valueForm: any) {
    try {
      const formatData = {
        name: valueForm.name,
        components: valueForm.components,
        periodicity: {
          type: valueForm.type,
          interval: valueForm.interval,
          date: valueForm.date,
        },
        activities: valueForm.activities,
      }

      createSystem(formatData)

      form.resetFields()
    } catch (err) {}
  }

  return (
    <Card
      title={
        <Space size="large">
          <Link to="/manutencao/sistemas">
            <ArrowLeftOutlined />
          </Link>
          <b>{translate('SystemCreate.modalTitle')}</b>
        </Space>
      }
      extra={
        <Space size="middle">
          <Button
            type="primary"
            icon={<SaveOutlined />}
            htmlType="submit"
            form="createSystem"
            id="btn_create_system"
          >
            {translate('SystemCreate.saveButton')}
          </Button>
        </Space>
      }
      bordered={false}
      style={{ width: '100%', padding: 30 }}
    >
      <Form
        id="createSystem"
        layout="vertical"
        onFinish={onCreateSystem}
        form={form}
      >
        <Row>
          <Col span={12}>
            <Form.Item
              label={translate('SystemCreate.nameLabel')}
              name="name"
              rules={[
                {
                  required: true,
                  message: translate('SystemCreate.nameRule'),
                },
              ]}
            >
              <Input placeholder={translate('SystemCreate.namePlaceholder')} />
            </Form.Item>
          </Col>
        </Row>

        <Divider>{translate('SystemCreate.periodicityText')}</Divider>
        <Row gutter={12}>
          <Col span={8}>
            <Form.Item label={translate('SystemCreate.typeText')} name="type">
              <Select
                style={{ width: '100%' }}
                placeholder={translate('SystemCreate.typeTextPlaceholder')}
              >
                <Select.Option value="day">
                  {translate('SystemCreate.dayText')}
                </Select.Option>
                <Select.Option value="sem">
                  {translate('SystemCreate.weekText')}
                </Select.Option>
                <Select.Option value="men">
                  {translate('SystemCreate.monthText')}
                </Select.Option>
                <Select.Option value="anu">
                  {translate('SystemCreate.yearText')}
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={translate('SystemCreate.typeText')}
              name="interval"
            >
              <InputNumber
                style={{ width: '100%' }}
                placeholder={translate('SystemCreate.intervalTextPlaceholder')}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label={translate('SystemCreate.dateText')} name="date">
              <DatePicker style={{ width: '100%' }} format="DD/MM/YYYY" />
            </Form.Item>
          </Col>
        </Row>

        <Divider />

        <Form.List name="components">
          {(fields, { add, remove }) => (
            <Row gutter={10}>
              {fields.map(({ key, name, ...restField }) => (
                <Fragment key={key}>
                  <Col span={8}>
                    <Form.Item
                      {...restField}
                      label={
                        <Space>
                          <Tooltip
                            title={translate(
                              'SystemCreate.removeActivityButton',
                            )}
                          >
                            <Button
                              ghost
                              shape="circle"
                              icon={<DeleteOutlined />}
                              onClick={() => remove(name)}
                              size="small"
                              danger
                            />
                          </Tooltip>
                          {translate('SystemCreate.componentLabel')}
                        </Space>
                      }
                      name={[name, 'name']}
                    >
                      <Input.TextArea
                        placeholder={translate(
                          'SystemCreate.componentPlaceholder',
                        )}
                      />
                    </Form.Item>
                  </Col>
                </Fragment>
              ))}
              <Col span={24}>
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    {translate('UpdateSystem.AddComponent')}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          )}
        </Form.List>

        <Form.List name="activities">
          {(fields, { add, remove }) => (
            <Row gutter={10}>
              {fields.map(({ key, name, ...restField }) => (
                <Fragment key={key}>
                  <Col span={8}>
                    <Form.Item
                      {...restField}
                      label={
                        <Space>
                          <Tooltip
                            title={translate(
                              'SystemCreate.removeActivityButton',
                            )}
                          >
                            <Button
                              ghost
                              shape="circle"
                              icon={<DeleteOutlined />}
                              onClick={() => remove(name)}
                              size="small"
                              danger
                            />
                          </Tooltip>
                          {translate('SystemCreate.activityLabel')}
                        </Space>
                      }
                      name={[name, 'activity']}
                    >
                      <Input.TextArea
                        placeholder={translate(
                          'SystemCreate.activityPlaceholder',
                        )}
                      />
                    </Form.Item>
                  </Col>
                </Fragment>
              ))}
              <Col span={24}>
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    {translate('SystemCreate.addActivityButton')}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          )}
        </Form.List>
      </Form>
    </Card>
  )
}

export default CreateSystem
