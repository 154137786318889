/* eslint-disable no-underscore-dangle */
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import {
  Modal,
  Button,
  Tooltip,
  Form,
  Input,
  Row,
  Col,
  Tabs,
  Space,
  Select,
  InputNumber,
  DatePicker,
} from 'antd'
import dayjs from 'dayjs'

import React, { Fragment, useState } from 'react'
import { useSystem } from '~/hooks/Maintenance/System/useSystem'
import { translate } from '~/utils/locale'

interface SystemProps {
  _id: string
  name: string
  createdAt: string
  activities: [{ activity: string }]
  components: [{ name: string }]
  periodicity: {
    type: string
    interval: number
    date: Date
  }
}
interface UpdateSystemData {
  data: SystemProps
}

const UpdateSystem: React.FC<UpdateSystemData> = ({ data }) => {
  const { updateSystem } = useSystem()

  const [isModalVisible, setIsModalVisible] = useState(false)

  const { TabPane } = Tabs

  const initialData = {
    name: data.name,
    type: data.periodicity.type,
    interval: data.periodicity.interval,
    date: dayjs(data.periodicity.date),
    activities: data.activities.map((a) => {
      return { activity: a.activity }
    }),
    components: data.components.map((a) => {
      return { name: a.name }
    }),
  }

  function onUpdateSystem(valueForm: SystemProps) {
    try {
      const formatData = {
        name: valueForm.name,
        activities: valueForm.activities,
        components: valueForm.components,
        periodicity: {
          type: valueForm.periodicity.type,
          interval: valueForm.periodicity.interval,
          date: valueForm.periodicity.date,
        },
      }

      updateSystem(formatData, data._id)
      setIsModalVisible(false)
    } catch (err) {
      setIsModalVisible(false)
    }
  }

  const showModal = () => {
    setIsModalVisible(true)
  }

  const closeModal = () => {
    setIsModalVisible(false)
  }

  return (
    <div>
      <Tooltip title={translate('UpdateSystem.EditSystem')}>
        <Button
          onClick={showModal}
          ghost
          shape="circle"
          type="primary"
          icon={<EditOutlined />}
        />
      </Tooltip>
      <Modal
        title={translate('UpdateSystem.EditSystem')}
        open={isModalVisible}
        onOk={closeModal}
        onCancel={closeModal}
        width="900px"
        footer={[
          <Button key="back" onClick={closeModal}>
            {translate('TasksRegistrationPauseUpdate.closeButton')}
          </Button>,
          <Button
            type="primary"
            key="update"
            htmlType="submit"
            form="sytemUpdateForm"
          >
            {translate('TasksRegistrationPauseUpdate.saveButton')}
          </Button>,
        ]}
      >
        <Form
          onFinish={onUpdateSystem}
          initialValues={initialData}
          layout="vertical"
          id="sytemUpdateForm"
        >
          <Tabs>
            <TabPane tab={translate('UpdateSystem.Details')} key="detalhes">
              <Form.Item
                label={translate('UpdateSystem.SystemName')}
                name="name"
                rules={[
                  {
                    required: true,
                    message: translate('SystemCreate.nameRule'),
                  },
                ]}
              >
                <Input
                  placeholder={translate('SystemCreate.namePlaceholder')}
                />
              </Form.Item>

              <Form.List name="components">
                {(fields, { add, remove }) => (
                  <Row gutter={10}>
                    {fields.map(({ key, name, ...restField }) => (
                      <Fragment key={key}>
                        <Col span={8}>
                          <Form.Item
                            {...restField}
                            label={
                              <Space>
                                <Tooltip
                                  title={translate(
                                    'SystemCreate.DeleteComponent',
                                  )}
                                >
                                  <Button
                                    ghost
                                    shape="circle"
                                    icon={<DeleteOutlined />}
                                    onClick={() => remove(name)}
                                    size="small"
                                    danger
                                  />
                                </Tooltip>
                                {translate('UpdateSystem.Component')}
                              </Space>
                            }
                            name={[name, 'name']}
                          >
                            <Input.TextArea
                              placeholder={translate(
                                'SystemCreate.componentPlaceholder',
                              )}
                            />
                          </Form.Item>
                        </Col>
                      </Fragment>
                    ))}
                    <Col span={24}>
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                        >
                          {translate('UpdateSystem.AddComponent')}
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                )}
              </Form.List>
            </TabPane>

            <TabPane tab="Atividades" key="activity">
              <Form.List name="activities">
                {(fields, { add, remove }) => (
                  <Row gutter={10}>
                    {fields.map(({ key, name, ...restField }) => (
                      <Fragment key={key}>
                        <Col span={8}>
                          <Form.Item
                            {...restField}
                            label={
                              <Space>
                                <Tooltip
                                  title={translate(
                                    'SystemCreate.DeleteActivity',
                                  )}
                                >
                                  <Button
                                    ghost
                                    shape="circle"
                                    icon={<DeleteOutlined />}
                                    onClick={() => remove(name)}
                                    size="small"
                                    danger
                                  />
                                </Tooltip>
                                {translate('UpdateSystem.Activity')}
                              </Space>
                            }
                            name={[name, 'activity']}
                          >
                            <Input.TextArea
                              placeholder={translate(
                                'SystemCreate.activityPlaceholder',
                              )}
                            />
                          </Form.Item>
                        </Col>
                      </Fragment>
                    ))}
                    <Col span={24}>
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                        >
                          {translate('UpdateSystem.AddActivity')}
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                )}
              </Form.List>
            </TabPane>

            <TabPane
              tab={translate('SystemCreate.periodicityText')}
              key="periodicity"
            >
              <Row gutter={12}>
                <Col span={8}>
                  <Form.Item
                    label={translate('SystemCreate.typeText')}
                    name="type"
                  >
                    <Select
                      style={{ width: '100%' }}
                      placeholder={translate(
                        'SystemCreate.typeTextPlaceholder',
                      )}
                    >
                      <Select.Option value="day">
                        {translate('SystemCreate.dayText')}
                      </Select.Option>
                      <Select.Option value="sem">
                        {translate('SystemCreate.weekText')}
                      </Select.Option>
                      <Select.Option value="men">
                        {translate('SystemCreate.monthText')}
                      </Select.Option>
                      <Select.Option value="anu">
                        {translate('SystemCreate.yearText')}
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label={translate('SystemCreate.intervalText')}
                    name="interval"
                  >
                    <InputNumber
                      style={{ width: '100%' }}
                      placeholder={translate(
                        'SystemCreate.intervalTextPlaceholder',
                      )}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label={translate('SystemCreate.dateText')}
                    name="date"
                  >
                    <DatePicker style={{ width: '100%' }} format="DD/MM/YYYY" />
                  </Form.Item>
                </Col>
              </Row>
            </TabPane>
          </Tabs>
        </Form>
      </Modal>
    </div>
  )
}

export default UpdateSystem
