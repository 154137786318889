/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useEffect } from 'react'

import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Tooltip,
} from 'antd'
import {
  ArrowLeftOutlined,
  DeleteOutlined,
  PlusOutlined,
  SaveOutlined,
} from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { translate } from '~/utils/locale'
import { useMaintenance } from '~/hooks/Maintenance/useMaintenance'
import ClientModalMaintenance from './Modal/Client'
import ContributorsMaintenanceTable from './Modal/Contributor/Table'

import SystemModalMaintenance from './Modal/System'

// import { Container } from './styles';

const CreateMaintenanceComponent: React.FC = () => {
  const { createMaintenance, clientData, contributorData, systemData } =
    useMaintenance()

  const [form] = Form.useForm()

  useEffect(() => {
    if (clientData?.id) {
      form.setFieldsValue({ customer: clientData?.name })
    }

    if (systemData?.id) {
      form.setFieldsValue({
        system: systemData?.name,
        components: systemData?.components,
        activities: systemData?.activities,
        type: systemData?.type,
        interval: systemData?.interval,
      })
    }

    if (contributorData?.id) {
      form.setFieldsValue({
        name: contributorData?.name,
        phone: contributorData?.phone,
      })
    }
  }, [form, clientData, contributorData, systemData])

  function onCreateMaintenance(valueForm: any) {
    try {
      const formatData = {
        customer: valueForm.customer,
        data_font: valueForm.data_font,
        responsible: {
          name: valueForm.name,
          phone: valueForm.phone,
        },
        systems: [
          {
            name: systemData?.name,
            components: valueForm.components,
            activities: valueForm.activities,
            periodicity: {
              type: valueForm.type,
              interval: valueForm.interval,
              date: valueForm.date,
            },
          },
        ],
      }

      createMaintenance(formatData)

      form.resetFields()
    } catch (err) {}
  }

  return (
    <Card
      title={
        <Space size="large">
          <Link to="/manutencao">
            <ArrowLeftOutlined />
          </Link>
          <b>{translate('MaintenanceCreate.NewMaintenance')}</b>
        </Space>
      }
      extra={
        <Space size="middle">
          <Button
            type="primary"
            icon={<SaveOutlined />}
            htmlType="submit"
            form="maintenance"
            id="btn_create_maintenance"
          >
            {translate('MaintenanceCreate.Save')}
          </Button>
        </Space>
      }
      bordered={false}
      style={{ width: '100%', padding: 30 }}
    >
      <Form
        onFinish={onCreateMaintenance}
        form={form}
        layout="vertical"
        id="maintenance"
      >
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item
              label={
                <Space>
                  <p>{translate('MaintenanceCreate.Client')}</p>

                  {clientData?.id ? <ClientModalMaintenance /> : ''}
                </Space>
              }
              name="customer"
              rules={[
                {
                  required: true,
                  message: 'Informe o cliente',
                },
              ]}
            >
              {clientData?.id ? <Input /> : <ClientModalMaintenance />}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={translate('MaintenanceCreate.DataSource')}
              name="data_font"
            >
              <Input
                placeholder={translate('MaintenanceCreate.EnterDataSource')}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={12}>
          <Col span={12}>
            <Form.Item
              label={
                <Space>
                  <p>{translate('MaintenanceCreate.Employee')}</p>
                  {contributorData?.id ? <ContributorsMaintenanceTable /> : ''}
                </Space>
              }
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Informe o colaborador',
                },
              ]}
            >
              {contributorData?.id ? (
                <Input />
              ) : (
                <ContributorsMaintenanceTable />
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={translate('MaintenanceCreate.ResponsiblePhone')}
              name="phone"
            >
              <Input placeholder="(99) 99999-9999" />
            </Form.Item>
          </Col>
        </Row>

        <Divider>{translate('MaintenanceCreate.Systems')}</Divider>

        <Row>
          <Col span={12}>
            <Form.Item
              label={
                <Space>
                  <p>{translate('MaintenanceCreate.System')}</p>

                  {systemData?.id ? <SystemModalMaintenance /> : ''}
                </Space>
              }
              name="system"
              rules={[
                {
                  required: true,
                  message: 'Informe o sistema',
                },
              ]}
            >
              {systemData?.id ? <Input /> : <SystemModalMaintenance />}
            </Form.Item>
          </Col>
        </Row>

        <Form.List name="components">
          {(fields, { add, remove }) => (
            <Row gutter={10}>
              {fields.map(({ key, name, ...restField }) => (
                <Fragment key={key}>
                  <Col span={8}>
                    <Form.Item
                      {...restField}
                      label={
                        <Space>
                          <Tooltip
                            title={translate(
                              'MaintenanceCreate.DeleteComponent',
                            )}
                          >
                            <Button
                              ghost
                              shape="circle"
                              icon={<DeleteOutlined />}
                              onClick={() => remove(name)}
                              size="small"
                              danger
                            />
                          </Tooltip>
                          {translate('MaintenanceCreate.Component')}
                        </Space>
                      }
                      name={[name, 'name']}
                    >
                      <Input.TextArea
                        placeholder={translate(
                          'SystemCreate.componentPlaceholder',
                        )}
                      />
                    </Form.Item>
                  </Col>
                </Fragment>
              ))}
              {systemData?.id ? (
                <Col span={24}>
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      {translate('MaintenanceCreate.AddComponent')}
                    </Button>
                  </Form.Item>
                </Col>
              ) : (
                ''
              )}
            </Row>
          )}
        </Form.List>

        <Form.List name="activities">
          {(fields, { add, remove }) => (
            <Row gutter={10}>
              {fields.map(({ key, name, ...restField }) => (
                <Fragment key={key}>
                  <Col span={8}>
                    <Form.Item
                      {...restField}
                      label={
                        <Space>
                          <Tooltip
                            title={translate(
                              'MaintenanceCreate.DeleteActivity',
                            )}
                          >
                            <Button
                              ghost
                              shape="circle"
                              icon={<DeleteOutlined />}
                              onClick={() => remove(name)}
                              size="small"
                              danger
                            />
                          </Tooltip>
                          {translate('MaintenanceCreate.Activity')}
                        </Space>
                      }
                      name={[name, 'activity']}
                    >
                      <Input.TextArea
                        placeholder={translate(
                          'SystemCreate.activityPlaceholder',
                        )}
                      />
                    </Form.Item>
                  </Col>
                </Fragment>
              ))}
              {systemData?.id ? (
                <Col span={24}>
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      {translate('MaintenanceCreate.AddActivity')}
                    </Button>
                  </Form.Item>
                </Col>
              ) : (
                ''
              )}
            </Row>
          )}
        </Form.List>

        <Divider>{translate('MaintenanceCreate.Periodicity')}</Divider>
        <Row gutter={12}>
          <Col span={8}>
            <Form.Item label={translate('MaintenanceCreate.Type')} name="type">
              <Select
                style={{ width: '100%' }}
                placeholder={translate('MaintenanceCreate.TypePlaceholder')}
              >
                <Select.Option value="day">
                  {translate('MaintenanceCreate.Daily')}
                </Select.Option>
                <Select.Option value="sem">
                  {translate('MaintenanceCreate.Weekly')}
                </Select.Option>
                <Select.Option value="men">
                  {translate('MaintenanceCreate.Monthly')}
                </Select.Option>
                <Select.Option value="anu">
                  {translate('MaintenanceCreate.Yearly')}
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={translate('MaintenanceCreate.Interval')}
              name="interval"
            >
              <InputNumber
                style={{ width: '100%' }}
                placeholder={translate('MaintenanceCreate.EnterInterval')}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={translate('MaintenanceCreate.StartDate')}
              name="date"
            >
              <DatePicker style={{ width: '100%' }} format="DD/MM/YYYY" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  )
}

export default CreateMaintenanceComponent
