export default {
  pt: {
    MaintenanceTable: {
      clientColumn: 'Cliente',
      periodicityColumn: 'Periodicidade',
      responsibleColumn: 'Colaborador',
      responsiblePhoneColumn: 'Celular do Colaborador',
      dataFontColumn: 'Data Fonte',
      systemColumn: 'Sistema',
      componentColumn: 'Componentes/Elementos',
      activityColumn: 'Atividades',
      deleteConfirmText: 'Deseja mesmo deletar o sistema?',
      activityText: 'atividade',
      activitiesText: 'atividades',
      actionsColumn: 'Ações',
      visualizationButon: 'Visualizar manutenções',
      deleteButton: 'Deletar',
      popConfirmText: 'Deseja mesmo deletar a manutenção?',
      periodicityText: 'A cada',
      yearsText: 'anos',
      yearText: 'ano',
      monthsText: 'meses',
      monthText: 'mes',
      weeksText: 'dias',
      weekText: 'dia',
      daysText: 'semanas',
      dayText: 'semana',
    },
  },
  en: {
    MaintenanceTable: {
      clientColumn: 'Customer',
      periodicityColumn: 'Periodicity',
      responsibleColumn: 'Collaborator',
      responsiblePhoneColumn: 'Collaborator Phone',
      dataFontColumn: 'Source Date',
      systemColumn: 'System',
      componentColumn: 'Components/Elements',
      activityColumn: 'Activities',
      deleteConfirmText: 'Do you really want to delete the system?',
      activityText: 'activity',
      activitiesText: 'activities',
      actionsColumn: 'Actions',
      visualizationButon: 'View maintenance',
      deleteButton: 'Delete',
      popConfirmText: 'Do you really want to delete maintenance?',
      periodicityText: 'Every',
      yearsText: 'years',
      yearText: 'year',
      monthsText: 'months',
      monthText: 'month',
      weeksText: 'days',
      weekText: 'day',
      daysText: 'weeks',
      dayText: 'week',
    },
  },
  es: {
    MaintenanceTable: {
      clientColumn: 'Cliente',
      periodicityColumn: 'Periodicidad',
      responsibleColumn: 'Colaborador',
      responsiblePhoneColumn: 'El celular del colaborador.',
      dataFontColumn: 'Fecha de origen',
      systemColumn: 'Sistema',
      componentColumn: 'Componente/Elementos',
      activityColumn: 'Actividades',
      deleteConfirmText: '¿Realmente quieres eliminar el sistema?',
      activityText: 'actividade',
      activitiesText: 'actividades',
      visualizationButon: 'Ver mantenimiento',
      actionsColumn: 'Aciones',
      deleteButton: 'Borrar',
      popConfirmText: '¿Realmente desea eliminar el mantenimiento?',
      periodicityText: 'Cada',
      yearsText: 'años',
      yearText: 'año',
      monthsText: 'meses',
      monthText: 'mes',
      weeksText: 'días',
      weekText: 'día',
      daysText: 'semanas',
      dayText: 'semana',
    },
  },
}
