/* eslint-disable @typescript-eslint/no-explicit-any */
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Space,
  Tooltip,
  message,
} from 'antd'
import React, { Fragment, useState } from 'react'
import { useShowMaintenance } from '~/hooks/Maintenance/useShowMaintenance'
import { translate } from '~/utils/locale'

// import { Container } from './styles';

interface SystemData {
  name: string
  components?: [{ component: string }]
  activities?: [{ activity: string }]
  type?: string
  interval?: number
  date?: Date
}

const AddSystem: React.FC = () => {
  const { addSystem } = useShowMaintenance()
  const [isModalVisible, setIsModalVisible] = useState(false)

  const showModal = () => {
    setIsModalVisible(true)
  }

  const closeModal = () => {
    setIsModalVisible(false)
  }

  const AddSystem = (data: SystemData) => {
    try {
      const system = {
        name: data.name,
        components: data.components,
        periodicity: {
          type: data.type,
          interval: data.interval,
          date: data.date,
        },
        activities: data.activities,
      }

      addSystem(system)
      setIsModalVisible(false)
    } catch (err: any) {
      message.error('Error!')
    }
  }

  return (
    <>
      <Tooltip title={translate('MaintenanceSystemTab.NewSystem')}>
        <Button onClick={showModal} type="primary" icon={<PlusOutlined />}>
          {translate('MaintenanceSystemTab.AddSystem')}
        </Button>
      </Tooltip>
      <Modal
        title={translate('MaintenanceSystemTab.AddSystem')}
        open={isModalVisible}
        onOk={closeModal}
        onCancel={closeModal}
        width="900px"
        style={{
          top: 30,
          maxHeight: 'calc(100vh - 100px)',
        }}
        footer={[
          <Button key="back" onClick={closeModal}>
            {translate('MaintenanceDetailsTab.Cancel')}
          </Button>,
          <Button
            type="primary"
            key="update"
            htmlType="submit"
            form="sytemAddSystemMaintenanceForm"
          >
            {translate('MaintenanceDetailsTab.Save')}
          </Button>,
        ]}
      >
        <Form
          layout="vertical"
          onFinish={AddSystem}
          id="sytemAddSystemMaintenanceForm"
        >
          <Form.Item label={translate('SystemCreate.nameLabel')} name="name">
            <Input
              style={{ width: '100%' }}
              placeholder={translate('SystemCreate.namePlaceholder')}
            />
          </Form.Item>

          <Divider>{translate('SystemCreate.periodicityText')}</Divider>
          <Row gutter={12}>
            <Col span={8}>
              <Form.Item label={translate('SystemCreate.typeText')} name="type">
                <Select
                  style={{ width: '100%' }}
                  placeholder={translate('SystemCreate.typeTextPlaceholder')}
                >
                  <Select.Option value="day">
                    {translate('SystemCreate.dayText')}
                  </Select.Option>
                  <Select.Option value="sem">
                    {translate('SystemCreate.weekText')}
                  </Select.Option>
                  <Select.Option value="men">
                    {translate('SystemCreate.monthText')}
                  </Select.Option>
                  <Select.Option value="anu">
                    {translate('SystemCreate.yearText')}
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={translate('SystemCreate.intervalText')}
                name="interval"
              >
                <InputNumber
                  style={{ width: '100%' }}
                  placeholder={translate(
                    'SystemCreate.intervalTextPlaceholder',
                  )}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={translate('SystemCreate.dateText')} name="date">
                <DatePicker style={{ width: '100%' }} format="DD/MM/YYYY" />
              </Form.Item>
            </Col>
          </Row>

          <Divider />

          <Form.List name="components">
            {(fields, { add, remove }) => (
              <Row gutter={10}>
                {fields.map(({ key, name, ...restField }) => (
                  <Fragment key={key}>
                    <Col span={12}>
                      <Form.Item
                        {...restField}
                        label={
                          <Space>
                            <Tooltip
                              title={translate(
                                'SystemCreate.removeActivityButton',
                              )}
                            >
                              <Button
                                ghost
                                shape="circle"
                                icon={<DeleteOutlined />}
                                onClick={() => remove(name)}
                                size="small"
                                danger
                              />
                            </Tooltip>
                            {translate('SystemCreate.componentLabel')}
                          </Space>
                        }
                        name={[name, 'name']}
                      >
                        <Input.TextArea
                          placeholder={translate(
                            'SystemCreate.componentPlaceholder',
                          )}
                        />
                      </Form.Item>
                    </Col>
                  </Fragment>
                ))}
                <Col span={24}>
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      {translate('UpdateSystem.AddComponent')}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            )}
          </Form.List>

          <Form.List name="activities">
            {(fields, { add, remove }) => (
              <Row gutter={10}>
                {fields.map(({ key, name, ...restField }) => (
                  <Fragment key={key}>
                    <Col span={8}>
                      <Form.Item
                        {...restField}
                        label={
                          <Space>
                            <Tooltip
                              title={translate(
                                'SystemCreate.removeActivityButton',
                              )}
                            >
                              <Button
                                ghost
                                shape="circle"
                                icon={<DeleteOutlined />}
                                onClick={() => remove(name)}
                                size="small"
                                danger
                              />
                            </Tooltip>
                            {translate('SystemCreate.activityLabel')}
                          </Space>
                        }
                        name={[name, 'activity']}
                      >
                        <Input.TextArea
                          placeholder={translate(
                            'SystemCreate.activityPlaceholder',
                          )}
                        />
                      </Form.Item>
                    </Col>
                  </Fragment>
                ))}
                <Col span={24}>
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      {translate('SystemCreate.addActivityButton')}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            )}
          </Form.List>
        </Form>
      </Modal>
    </>
  )
}

export default AddSystem
