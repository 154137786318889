/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, createContext, useState } from 'react'
import { useQuery } from 'react-query'

import { message } from 'antd'

import api from '~/services/api'

import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useLocale } from '~/hooks/locale/useLocale'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'

interface SystemProps {
  _id: string
  name: string
  createdAt: string
  activities: [{ activity: string }]
  components?: [{ name: string }]
  periodicity: {
    type: string
    interval: number
    date: Date
  }
}

interface SystemContextData {
  system?: SystemProps[]
  createSystem(valueForm: object | undefined): void
  updateSystem(valueForm: object | undefined, systemId: string): void
  deleteSystem(systemId: string): void
  isLoading: boolean
  refetch: () => void
  isFetching: boolean
  systemTotal?: number
}

const SystemContext = createContext<SystemContextData>({} as SystemContextData)

export const SystemProvider: React.FC = ({ children }) => {
  const { selectedCompany } = useCompanies()
  const { locale } = useLocale()

  const [systemTotal, setSystemTotal] = useState<number>()

  const {
    data: system,
    isLoading,
    refetch,
    isFetching,
  } = useQuery(
    [`sistemas/gestao${selectedCompany}`, selectedCompany, systemTotal],

    async () => {
      try {
        const response = await api.get(`/company/${selectedCompany}/systems`)

        const { data } = response

        setSystemTotal(data.total)

        return data.results
      } catch (err: any) {
        ShowError(err.message, translate('useSystem.getErrorMessage'), locale)
      }
    },
  )

  async function createSystem(valueForm: object) {
    try {
      await api.post(`/company/${selectedCompany}/system`, valueForm)

      refetch()
      message.success(translate('useSystem.createSuccessMessage'))
    } catch (err: any) {
      ShowError(err.message, translate('useSystem.createErrorMessage'), locale)
    }
  }

  async function updateSystem(valueForm: object, systemId: string) {
    try {
      await api.put(`/company/${selectedCompany}/system/${systemId}`, valueForm)

      message.success(translate('useSystem.updateSuccessMessage'))
      refetch()
    } catch (err: any) {
      ShowError(err.message, translate('useSystem.updateErrorMessage'), locale)
    }
  }

  async function deleteSystem(systemId: string) {
    try {
      await api.delete(`/company/${selectedCompany}/system/${systemId}`)

      refetch()
      message.success(translate('useSystem.deleteSuccessMessage'))
    } catch (err: any) {
      ShowError(err.message, translate('useSystem.deleteErrorMessage'), locale)
    }
  }

  return (
    <SystemContext.Provider
      value={{
        system,
        createSystem,
        updateSystem,
        deleteSystem,
        isLoading,
        refetch,
        isFetching,
        systemTotal,
      }}
    >
      {children}
    </SystemContext.Provider>
  )
}

export function useSystem(): SystemContextData {
  const context = useContext(SystemContext)

  if (!context) {
    throw new Error('useAuth must be used within an AutoProvider')
  }

  return context
}
