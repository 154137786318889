export default {
  pt: {
    MaintenanceBoard: {
      cardTitle: 'Gestão de Manutenção',
      totalText: 'Total de',
      maintenanceText: 'serviço de manutenção',
      maintenancesText: 'serviços de manutenção',
      newMaintenanceButton: 'Nova Manutenção',
    },
  },
  en: {
    MaintenanceBoard: {
      cardTitle: 'Maintenance Management',
      totalText: 'Total of',
      maintenanceText: 'maintenance service',
      maintenancesText: 'maintenance services',
      newMaintenanceButton: 'New Maintenance',
    },
  },
  es: {
    MaintenanceBoard: {
      cardTitle: 'Gestión de mantenimiento',
      totalText: 'Total de',
      maintenanceText: 'servicio de mantenimiento',
      maintenancesText: 'servicios de mantenimiento',
      newMaintenanceButton: 'Nuevo mantenimiento',
    },
  },
}
