export default {
  pt: {
    SystemBoard: {
      cardTitle: 'Gestão de Manutenção - Sistemas',
      totalText: 'Total de',
      systemText: 'sistema',
      systemsText: 'sistemas',
    },
  },
  en: {
    SystemBoard: {
      cardTitle: 'Maintenance Management - Systems',
      totalText: 'Total of',
      systemText: 'system',
      systemsText: 'systems',
    },
  },
  es: {
    SystemBoard: {
      cardTitle: 'Gestión de mantenimiento - Sistemas',
      totalText: 'Total de',
      systemText: 'sistema',
      systemsText: 'sistemas',
    },
  },
}
