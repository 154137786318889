import React from 'react'
import MaintenanceTabs from '~/components/DataManipulation/Maintenance/Tabs'
import { ListMaintenanceProvider } from '~/hooks/Maintenance/useMaintenance'

import { MaintenanceShowProvider } from '~/hooks/Maintenance/useShowMaintenance'
import { ClientsListTaskProvider } from '~/hooks/Tasks/Create/clientsListTask'
import { ContributorsTaskProvider } from '~/hooks/Tasks/Create/useContributorTask'

// import { Container } from './styles';

const ShowMaintenance: React.FC = () => {
  return (
    <MaintenanceShowProvider>
      <ClientsListTaskProvider>
        <ContributorsTaskProvider>
          <ListMaintenanceProvider>
            <MaintenanceTabs />
          </ListMaintenanceProvider>
        </ContributorsTaskProvider>
      </ClientsListTaskProvider>
    </MaintenanceShowProvider>
  )
}

export default ShowMaintenance
