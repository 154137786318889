/* eslint-disable @typescript-eslint/no-explicit-any */
import { EditOutlined } from '@ant-design/icons'
import { Button, Form, Input, Modal, Tooltip } from 'antd'
import React, { useState } from 'react'
import { useShowMaintenance } from '~/hooks/Maintenance/useShowMaintenance'
import { translate } from '~/utils/locale'

// import { Container } from './styles';

interface ActivityProps {
  id: string
  systemId: string
  activity: string
}

interface ActivityFormProps {
  activity: string
}

const UpdateActivity: React.FC<ActivityProps> = ({
  id,
  systemId,
  activity,
}) => {
  const { updateActivity } = useShowMaintenance()

  const [isModalVisible, setIsModalVisible] = useState(false)

  const initialValue = {
    activity,
  }

  const showModal = () => {
    setIsModalVisible(true)
  }

  const closeModal = () => {
    setIsModalVisible(false)
  }

  function UpdateActivity(data: ActivityFormProps) {
    const updatedActivity = {
      activity: data.activity,
    }

    try {
      updateActivity(updatedActivity, systemId, id)
      setIsModalVisible(false)
    } catch (err: any) {
      console.log(err)
    }
  }

  return (
    <>
      <Tooltip title={translate('MaintenanceSystemTab.EditActivity')}>
        <Button
          type="primary"
          ghost
          shape="circle"
          size="small"
          icon={<EditOutlined />}
          style={{ marginLeft: 5 }}
          onClick={showModal}
        />
      </Tooltip>
      <Modal
        title={translate('MaintenanceSystemTab.EditActivity')}
        open={isModalVisible}
        onOk={closeModal}
        onCancel={closeModal}
        footer={[
          <Button key="back" onClick={closeModal}>
            {translate('TasksRegistrationPauseUpdate.closeButton')}
          </Button>,
          <Button
            type="primary"
            key="update"
            htmlType="submit"
            form="systemUpdateActivityMain"
          >
            {translate('TasksRegistrationPauseUpdate.saveButton')}
          </Button>,
        ]}
      >
        <Form
          layout="vertical"
          initialValues={initialValue}
          onFinish={UpdateActivity}
          id="systemUpdateActivityMain"
        >
          <Form.Item
            name="activity"
            label={translate('MaintenanceSystemTab.Activity')}
          >
            <Input.TextArea />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default UpdateActivity
