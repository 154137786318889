import styled from 'styled-components'

export const Container = styled.div`
  .text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-content: flex-start;
    padding-bottom: 23px;
    font-size: 16px;

    svg {
      margin-right: 5px;
    }
  }

  .span-tag {
    line-height: 45px;
    font-size: 14px;
    padding-inline: 1rem;
    border-radius: 10px;
  }
`
