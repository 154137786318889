import { DeleteFilled, LoadingOutlined } from '@ant-design/icons'
import {
  Button,
  Card,
  Col,
  Collapse,
  Popconfirm,
  Row,
  Tag,
  Tooltip,
} from 'antd'
import React from 'react'
import { useShowMaintenance } from '~/hooks/Maintenance/useShowMaintenance'
import { LoadingIndicator } from '~/pages/Contributor/ShowContributor/styles'

import Text from 'antd/lib/typography/Text'

import { Container } from './styles'
import { FiUser } from 'react-icons/fi'
import { translate } from '~/utils/locale'
import { format } from 'date-fns'
import AddComponent from './AddComponent'
import AddActivity from './AddActivity'
import UpdateActivity from './AddActivity/Update'
import UpdateComponent from './AddComponent/Update'

const SystemsMaintenanceTabs: React.FC = () => {
  const {
    isLoading,
    maintenance,
    deleteActivity,
    deleteComponent,
    deleteSystem,
  } = useShowMaintenance()

  if (isLoading || !maintenance) {
    return (
      <LoadingIndicator>
        <LoadingOutlined style={{ fontSize: 64, margin: 'auto' }} spin />
      </LoadingIndicator>
    )
  }

  return (
    <Container>
      {maintenance.systems.map((system) => (
        <Card
          key={system._id}
          bordered={false}
          title={system?.name}
          style={{
            display: 'inline-block',
            marginTop: '1rem',
            width: '100%',
          }}
          extra={
            <Tooltip title={translate('MaintenanceSystemTab.DeleteSystem')}>
              <Popconfirm
                title={translate('MaintenanceSystemTab.SystemConfirm')}
                onConfirm={() => deleteSystem(system._id)}
              >
                <Button ghost danger shape="circle" icon={<DeleteFilled />} />
              </Popconfirm>
            </Tooltip>
          }
        >
          <Row>
            <Col span={8}>
              <div className="text">
                <div>
                  <FiUser size={16} />
                  <Text style={{ fontSize: 16 }} strong>
                    {translate('MaintenanceSystemTab.SystemName')}
                  </Text>
                </div>
                {maintenance.customer ? (
                  <Text style={{ fontSize: 16 }}>{system?.name}</Text>
                ) : (
                  <Text style={{ fontSize: 16 }}>
                    {translate('TasksTabsDetails.noToText')}
                  </Text>
                )}
              </div>
            </Col>
            <Col span={8}>
              <div className="text">
                <div>
                  <FiUser size={16} />
                  <Text style={{ fontSize: 16 }} strong>
                    {translate('MaintenanceSystemTab.Periodicity')}
                  </Text>
                </div>
                {system.periodicity ? (
                  <>
                    {system.periodicity.type === 'day' ? (
                      <Text style={{ fontSize: 16 }}>
                        {translate('MaintenanceSystemTab.Text')}{' '}
                        {system.periodicity.interval}{' '}
                        {translate('MaintenanceSystemTab.Days')}
                      </Text>
                    ) : (
                      ''
                    )}

                    {system.periodicity.type === 'sem' ? (
                      <Text style={{ fontSize: 16 }}>
                        {translate('MaintenanceSystemTab.Text')}{' '}
                        {system.periodicity.interval}{' '}
                        {translate('MaintenanceSystemTab.Weeks')}
                      </Text>
                    ) : (
                      ''
                    )}

                    {system.periodicity.type === 'men' ? (
                      <Text style={{ fontSize: 16 }}>
                        {translate('MaintenanceSystemTab.Text')}{' '}
                        {system.periodicity.interval}{' '}
                        {translate('MaintenanceSystemTab.Months')}
                      </Text>
                    ) : (
                      ''
                    )}

                    {system.periodicity.type === 'anu' ? (
                      <Text style={{ fontSize: 16 }}>
                        {translate('MaintenanceSystemTab.Text')}{' '}
                        {system.periodicity.interval}{' '}
                        {translate('MaintenanceSystemTab.Years')}
                      </Text>
                    ) : (
                      ''
                    )}
                  </>
                ) : (
                  <Text style={{ fontSize: 16 }}>
                    {translate('TasksTabsDetails.noToText')}
                  </Text>
                )}
              </div>
            </Col>
            <Col span={8}>
              <div className="text">
                <div>
                  <FiUser size={16} />
                  <Text style={{ fontSize: 16 }} strong>
                    {translate('MaintenanceSystemTab.PeriodicityStartDate')}
                  </Text>
                </div>
                {system.periodicity?.date ? (
                  <Text style={{ fontSize: 16 }}>
                    {format(new Date(system.periodicity?.date), 'dd/MM/yyyy')}
                  </Text>
                ) : (
                  <Text style={{ fontSize: 16 }}>
                    {translate('TasksTabsDetails.noToText')}
                  </Text>
                )}
              </div>
            </Col>
          </Row>

          <Collapse bordered={false}>
            <Collapse.Panel
              header={translate('MaintenanceSystemTab.ComponentsElements')}
              key="components"
              style={{ marginBottom: 10 }}
              extra={<AddComponent id={system._id} />}
            >
              {system.components?.map((component) => (
                <Tag className="span-tag" color="geekblue" key={component._id}>
                  {component.name}
                  <Tooltip
                    title={translate('MaintenanceSystemTab.DeleteComponent')}
                  >
                    <Popconfirm
                      title={translate('MaintenanceSystemTab.ComponentConfirm')}
                      onConfirm={() =>
                        deleteComponent(system._id, component._id)
                      }
                    >
                      <Button
                        ghost
                        danger
                        shape="circle"
                        size="small"
                        icon={<DeleteFilled />}
                        style={{ marginLeft: 10 }}
                      />
                    </Popconfirm>
                  </Tooltip>
                  <UpdateComponent
                    id={component._id}
                    systemId={system._id}
                    name={component.name}
                  />
                </Tag>
              ))}
            </Collapse.Panel>
          </Collapse>

          <Collapse bordered={false}>
            <Collapse.Panel
              header={translate('MaintenanceSystemTab.Activities')}
              key="activities"
              extra={<AddActivity id={system._id} />}
            >
              {system.activities?.map((activity) => (
                <Tag color="processing" className="span-tag" key={activity._id}>
                  {activity.activity}
                  <Tooltip
                    title={translate('MaintenanceSystemTab.DeleteActivity')}
                  >
                    <Popconfirm
                      title={translate('MaintenanceSystemTab.ActivityConfirm')}
                      onConfirm={() => deleteActivity(system._id, activity._id)}
                    >
                      <Button
                        ghost
                        danger
                        shape="circle"
                        size="small"
                        icon={<DeleteFilled />}
                        style={{ marginLeft: 10 }}
                      />
                    </Popconfirm>
                  </Tooltip>
                  <UpdateActivity
                    id={activity._id}
                    systemId={system._id}
                    activity={activity.activity}
                  />
                </Tag>
              ))}
            </Collapse.Panel>
          </Collapse>
        </Card>
      ))}
    </Container>
  )
}

export default SystemsMaintenanceTabs
