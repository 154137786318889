/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { EditOutlined, LoadingOutlined } from '@ant-design/icons'
import { Button, Card, Col, Row, Space } from 'antd'
import React from 'react'
import { FiUser } from 'react-icons/fi'
import { useShowMaintenance } from '~/hooks/Maintenance/useShowMaintenance'
import { LoadingIndicator } from '~/pages/Contributor/ShowContributor/styles'
import { translate } from '~/utils/locale'

import Text from 'antd/lib/typography/Text'

import { Container } from './styles'
import NumberFormat from 'react-number-format'

const DetailsMaintenanceTabs: React.FC = () => {
  const { isLoading, maintenance, setIsEdit } = useShowMaintenance()

  if (isLoading || !maintenance) {
    return (
      <LoadingIndicator>
        <LoadingOutlined style={{ fontSize: 64, margin: 'auto' }} spin />
      </LoadingIndicator>
    )
  }

  return (
    <Card
      bordered={false}
      title={translate('MaintenanceDetailsTab.MaintenanceDetails')}
      extra={
        <Space>
          <Button
            id="btn-edit-maintenance"
            type="primary"
            icon={<EditOutlined />}
            onClick={() => setIsEdit(true)}
          >
            {translate('MaintenanceDetailsTab.Edit')}
          </Button>
        </Space>
      }
      style={{
        display: 'inline-block',
        width: '100%',
      }}
    >
      <Container>
        <Row>
          <Col span={8}>
            <div className="text">
              <div>
                <FiUser size={16} />
                <Text style={{ fontSize: 16 }} strong>
                  {translate('MaintenanceDetailsTab.ClientCondominium')}
                </Text>
              </div>
              {maintenance.customer ? (
                <Text style={{ fontSize: 16 }}>{maintenance.customer}</Text>
              ) : (
                <Text style={{ fontSize: 16 }}>
                  {translate('TasksTabsDetails.noToText')}
                </Text>
              )}
            </div>
          </Col>
          <Col span={8}>
            <div className="text">
              <div>
                <FiUser size={16} />
                <Text style={{ fontSize: 16 }} strong>
                  {translate('MaintenanceDetailsTab.ResponsibleEmployee')}
                </Text>
              </div>
              {maintenance.responsible.name ? (
                <Text style={{ fontSize: 16 }}>
                  {maintenance.responsible.name}
                </Text>
              ) : (
                <Text style={{ fontSize: 16 }}>
                  {translate('TasksTabsDetails.noToText')}
                </Text>
              )}
            </div>
          </Col>

          <Col span={8}>
            <div className="text">
              <div>
                <FiUser size={16} />
                <Text style={{ fontSize: 16 }} strong>
                  {translate('MaintenanceDetailsTab.ResponsibleEmployeePhone')}
                </Text>
              </div>
              {maintenance.responsible.phone ? (
                <Text style={{ fontSize: 16 }}>
                  <NumberFormat
                    value={maintenance.responsible.phone}
                    displayType="text"
                    format="+## (##) #####-####"
                  />
                </Text>
              ) : (
                <Text style={{ fontSize: 16 }}>
                  {translate('TasksTabsDetails.noToText')}
                </Text>
              )}
            </div>
          </Col>
        </Row>

        <Row>
          <Col span={8}>
            <div className="text">
              <div>
                <FiUser size={16} />
                <Text style={{ fontSize: 16 }} strong>
                  {translate('MaintenanceDetailsTab.DataSource')}
                </Text>
              </div>
              {maintenance.data_font ? (
                <Text style={{ fontSize: 16 }}>{maintenance.data_font}</Text>
              ) : (
                <Text style={{ fontSize: 16 }}>
                  {translate('TasksTabsDetails.noToText')}
                </Text>
              )}
            </div>
          </Col>

          <Col span={8}>
            <div className="text">
              <div>
                <FiUser size={16} />
                <Text style={{ fontSize: 16 }} strong>
                  {translate('MaintenanceDetailsTab.SystemsCount')}
                </Text>
              </div>
              {maintenance.systems ? (
                <Text style={{ fontSize: 16 }}>
                  {maintenance.systems.length} sistemas
                </Text>
              ) : (
                <Text style={{ fontSize: 16 }}>
                  {translate('TasksTabsDetails.noToText')}
                </Text>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </Card>
  )
}

export default DetailsMaintenanceTabs
