/* eslint-disable react/jsx-one-expression-per-line */
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Card, Space } from 'antd'
import Text from 'antd/lib/typography/Text'
import React from 'react'
import { Link } from 'react-router-dom'

import { useMaintenance } from '~/hooks/Maintenance/useMaintenance'
import { translate } from '~/utils/locale'
import MaintenanceTable from './Table'

const MaintenanceBoard: React.FC = () => {
  const { maintenanceTotal, isLoading, isFetching } = useMaintenance()

  return (
    <Card
      title={
        <Space size="large">
          <b>{translate('MaintenanceBoard.cardTitle')}</b>
          {isLoading ? (
            <LoadingOutlined spin />
          ) : (
            <Text
              type="secondary"
              style={{ fontWeight: 'normal', fontSize: 14 }}
            >
              {maintenanceTotal !== undefined ? (
                <>
                  {maintenanceTotal === 1
                    ? `${translate(
                        'MaintenanceBoard.totalText',
                      )} ${maintenanceTotal} ${translate(
                        'MaintenanceBoard.maintenanceText',
                      )}`
                    : `${translate(
                        'MaintenanceBoard.totalText',
                      )} ${maintenanceTotal} ${translate(
                        'MaintenanceBoard.maintenancesText',
                      )}`}
                </>
              ) : (
                ''
              )}
            </Text>
          )}
          {!isLoading && isFetching && <LoadingOutlined spin />}
        </Space>
      }
      extra={
        <Space size="small">
          <Link to="/manutencao/novo">
            <Button type="primary" icon={<PlusOutlined />}>
              {translate('MaintenanceBoard.newMaintenanceButton')}
            </Button>
          </Link>
        </Space>
      }
      bordered={false}
      style={{ width: '100%', padding: 30 }}
    >
      <MaintenanceTable />
    </Card>
  )
}

export default MaintenanceBoard
