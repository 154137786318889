/* eslint-disable react/jsx-one-expression-per-line */
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Card, Space } from 'antd'
import Text from 'antd/lib/typography/Text'
import React from 'react'
import { useSystem } from '~/hooks/Maintenance/System/useSystem'
import { translate } from '~/utils/locale'

import SystemTable from './Table'
import { Link } from 'react-router-dom'

const SystemBoard: React.FC = () => {
  const { systemTotal, isLoading, isFetching } = useSystem()

  return (
    <Card
      title={
        <Space size="large">
          <b>{translate('SystemBoard.cardTitle')}</b>
          {isLoading ? (
            <LoadingOutlined spin />
          ) : (
            <Text
              type="secondary"
              style={{ fontWeight: 'normal', fontSize: 14 }}
            >
              {systemTotal !== undefined ? (
                <>
                  {systemTotal === 1
                    ? `${translate(
                        'SystemBoard.totalText',
                      )} ${systemTotal} ${translate('SystemBoard.systemText')}`
                    : `${translate(
                        'SystemBoard.totalText',
                      )} ${systemTotal} ${translate(
                        'SystemBoard.systemsText',
                      )}`}
                </>
              ) : (
                ''
              )}
            </Text>
          )}
          {!isLoading && isFetching && <LoadingOutlined spin />}
        </Space>
      }
      extra={
        <Space size="small">
          <Link to="/manutencao/sistemas/novo">
            <Button type="primary" icon={<PlusOutlined />}>
              {translate('SystemCreate.modalTitle')}
            </Button>
          </Link>
        </Space>
      }
      bordered={false}
      style={{ width: '100%', padding: 30 }}
    >
      <SystemTable />
    </Card>
  )
}

export default SystemBoard
