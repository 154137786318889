import React from 'react'

import { Tabs } from 'antd'
import { PageHeader } from '@ant-design/pro-components'

import { useHistory, useParams } from 'react-router-dom'

import { useShowMaintenance } from '~/hooks/Maintenance/useShowMaintenance'
import DetailsMaintenanceTabs from './Details'
import SystemsMaintenanceTabs from './Systems'
import AddSystem from './Systems/Add'
import UpdateMaintenance from './Update'
import { translate } from '~/utils/locale'

const MaintenanceTabs: React.FC = () => {
  const { maintenance, isEdit } = useShowMaintenance()
  const { maintenanceId, tab } = useParams<{
    maintenanceId: string
    tab: string
  }>()

  const history = useHistory()

  const { TabPane } = Tabs

  const handleTabChange = (key: string) => {
    const path = window.location.pathname.split('/')

    if (key === 'detalhes') {
      history.replace({
        pathname: `/manutencao/${path[2]}/${maintenanceId}/`,
      })
    } else {
      history.replace({
        pathname: `/manutencao/${path[2]}/${maintenanceId}/${key}`,
      })
    }
  }

  return (
    <>
      <PageHeader
        className="site-page-header"
        onBack={() => window.history.back()}
        title={translate('MaintenanceDetailsTab.MaintenanceManagement')}
        subTitle={maintenance?.customer}
      />
      <Tabs
        onTabClick={(key: string) => handleTabChange(key)}
        defaultActiveKey={tab ? `${tab}` : 'detalhes'}
        style={{ paddingLeft: 20, paddingRight: 20 }}
        tabBarExtraContent={tab === 'sistemas' ? <AddSystem /> : ''}
      >
        <TabPane
          tab={translate('MaintenanceDetailsTab.Details')}
          key="detalhes"
        >
          {!isEdit ? <DetailsMaintenanceTabs /> : <UpdateMaintenance />}
        </TabPane>
        <TabPane
          tab={translate('MaintenanceDetailsTab.Systems')}
          key="sistemas"
        >
          <SystemsMaintenanceTabs />
        </TabPane>
      </Tabs>
    </>
  )
}

export default MaintenanceTabs
