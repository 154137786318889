/* eslint-disable @typescript-eslint/no-explicit-any */
import { PlusOutlined } from '@ant-design/icons'
import { Button, Form, Input, Modal, Tooltip, message } from 'antd'
import React, { useState } from 'react'
import { useShowMaintenance } from '~/hooks/Maintenance/useShowMaintenance'
import { translate } from '~/utils/locale'

// import { Container } from './styles';

interface SystemData {
  name: string
}

interface SystemProps {
  id: string
}

const AddComponent: React.FC<SystemProps> = ({ id }) => {
  const { addComponent } = useShowMaintenance()
  const [isModalVisible, setIsModalVisible] = useState(false)

  const showModal = () => {
    setIsModalVisible(true)
  }

  const closeModal = () => {
    setIsModalVisible(false)
  }

  const AddComponent = (data: SystemData) => {
    try {
      const component = {
        name: data.name,
      }

      addComponent(component, id)
      setIsModalVisible(false)
    } catch (err: any) {
      message.error('Error!')
    }
  }

  return (
    <>
      <Tooltip title={translate('MaintenanceSystemTab.AddComponent')}>
        <Button
          shape="circle"
          ghost
          size="small"
          onClick={showModal}
          type="primary"
          icon={<PlusOutlined />}
        />
      </Tooltip>
      <Modal
        title={translate('MaintenanceSystemTab.AddComponentToSystem')}
        open={isModalVisible}
        onOk={closeModal}
        onCancel={closeModal}
        footer={[
          <Button key="back" onClick={closeModal}>
            {translate('TasksRegistrationPauseUpdate.closeButton')}
          </Button>,
          <Button
            type="primary"
            key="update"
            htmlType="submit"
            form="sytemAddComponentForm"
          >
            {translate('TasksRegistrationPauseUpdate.saveButton')}
          </Button>,
        ]}
      >
        <Form
          layout="vertical"
          onFinish={AddComponent}
          id="sytemAddComponentForm"
        >
          <Form.Item
            label={translate('MaintenanceSystemTab.ComponentElement')}
            name="name"
          >
            <Input
              style={{ width: '100%' }}
              placeholder={translate(
                'MaintenanceSystemTab.EnterComponentElement',
              )}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default AddComponent
