/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  LoadingOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  ReloadOutlined,
} from '@ant-design/icons'
import {
  Button,
  Modal,
  Space,
  Tooltip,
  Table,
  Popconfirm,
  Tag,
  Typography,
} from 'antd'
import { ColumnsType } from 'antd/lib/table'

import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import { translate } from '~/utils/locale'

import { useMaintenance } from '~/hooks/Maintenance/useMaintenance'
import { useSystem } from '~/hooks/Maintenance/System/useSystem'

interface SystemProps {
  _id: string
  name: string
  createdAt: string
  activities: [{ activity: string }]
  components?: [{ name: string }]
  periodicity: {
    type: string
    interval: number
    date: Date
  }
}

const SystemModalMaintenance: React.FC = () => {
  const { system, systemTotal, isFetching } = useSystem()
  const { systemData, setSystemData } = useMaintenance()

  const [isModalVisible, setIsModalVisible] = useState(false)

  async function handleClientTask(
    id: string,
    name: string,
    components: any,
    activities: any,
    type: string,
    interval: number,
    date: Date,
  ) {
    await setSystemData({
      id,
      name,
      components,
      activities,
      type,
      interval,
      date,
    })
    setIsModalVisible(false)
  }

  const remainingActivities = (activity: any) => {
    return activity.length - 1
  }

  const columns: ColumnsType<SystemProps> = [
    {
      title: translate('ClientsTable.columnActions'),
      align: 'center',
      key: 'remove',
      render: (record) => (
        <Popconfirm
          title={translate('MaintenanceCreate.ConfirmSystem')}
          onConfirm={() =>
            handleClientTask(
              record._id,
              record.name,
              record.components,
              record.activities,
              record.periodicity.type,
              record.periodicity.interval,
              record.periodicity.date,
            )
          }
        >
          <Tooltip title={translate('MaintenanceCreate.AddSystem')}>
            <Button size="small" shape="circle" icon={<PlusOutlined />} />
          </Tooltip>
        </Popconfirm>
      ),
    },
    {
      title: translate('SystemTable.nameColumn'),
      key: 'name',
      dataIndex: 'name',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: translate('SystemTable.componentColumn'),
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (record: SystemProps) => (
        <>
          {record?.components?.length === 1 ? (
            <Typography.Text>{record?.components?.[0].name}</Typography.Text>
          ) : (
            <Tag color="green">{record?.components?.length} componentes</Tag>
          )}
        </>
      ),
    },
    {
      title: translate('SystemTable.activityColumn'),
      key: 'activity',
      render: (record: SystemProps) => (
        <Space>
          <Tooltip title={record.activities[0].activity}>
            {record.activities[0].activity.length > 40
              ? `${record.activities[0].activity.substring(0, 40)}...`
              : record.activities[0].activity}
          </Tooltip>
          {record.activities.length > 1 ? (
            <Tag color="green">
              + {remainingActivities(record.activities)}{' '}
              {translate('SystemTable.activitiesText')}
            </Tag>
          ) : (
            ''
          )}
        </Space>
      ),
    },
  ]

  const showModal = () => {
    setIsModalVisible(true)
  }

  const closeModal = () => {
    setIsModalVisible(false)
  }

  return (
    <>
      {!systemData?.id ? (
        <Button
          icon={<PlusCircleOutlined />}
          onClick={showModal}
          style={{ width: '100%' }}
        >
          {translate('MaintenanceCreate.AddSystem')}
        </Button>
      ) : (
        <Button size="small" icon={<ReloadOutlined />} onClick={showModal}>
          {translate('MaintenanceCreate.EditSystem')}
        </Button>
      )}

      <Modal
        title={translate('MaintenanceCreate.AddSystem')}
        open={isModalVisible}
        onOk={closeModal}
        onCancel={closeModal}
        width="900px"
        style={{
          top: 30,
          maxHeight: 'calc(100vh - 100px)',
        }}
        footer={[
          <Space key="FooterProps">
            <Button key="back" onClick={closeModal}>
              {translate('TasksCreateModalClient.closeButton')}
            </Button>

            <Link target="_blank" to="/clientes/novo">
              <Button type="primary" icon={<PlusCircleOutlined />}>
                {translate('ClientsComponent.newCustomerButtonText')}
              </Button>
            </Link>
          </Space>,
        ]}
      >
        <Table
          rowKey={(record) => record._id}
          loading={{
            indicator: <LoadingOutlined />,
            spinning: isFetching,
            size: 'large',
          }}
          pagination={{
            total: systemTotal,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '50', '100', '200'],
            defaultPageSize: 5,
            showTotal: (total, range) => `${range[0]} - ${range[1]} / ${total}`,
          }}
          columns={columns}
          size="small"
          dataSource={system}
          scroll={{ x: 1000 }}
        />
      </Modal>
    </>
  )
}

export default SystemModalMaintenance
