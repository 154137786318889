/* eslint-disable @typescript-eslint/no-explicit-any */
import { CloseOutlined, LoadingOutlined } from '@ant-design/icons'
import { Button, Card, Col, Form, Input, Row, Space } from 'antd'
import React, { useEffect } from 'react'
import { useShowMaintenance } from '~/hooks/Maintenance/useShowMaintenance'
import { LoadingIndicator } from '~/pages/Contributor/ShowContributor/styles'
import { translate } from '~/utils/locale'
import ClientModalMaintenance from '../../Create/Modal/Client'
import { useMaintenance } from '~/hooks/Maintenance/useMaintenance'
import ContributorsMaintenanceTable from '../../Create/Modal/Contributor/Table'

// import { Container } from './styles';

interface UpdateMaintenanceProps {
  customer: string
  data_font: string
  name: string
  phone: string
}

const UpdateMaintenance: React.FC = () => {
  const { isLoading, maintenance, setIsEdit, updateMaintenance } =
    useShowMaintenance()

  const { clientData, contributorData } = useMaintenance()

  const [form] = Form.useForm()

  useEffect(() => {
    if (clientData?.id) {
      form.setFieldsValue({ customer: clientData?.name })
    }

    if (contributorData?.id) {
      form.setFieldsValue({
        name: contributorData?.name,
        phone: contributorData?.phone,
      })
    }
  }, [form, clientData, contributorData])

  if (isLoading || !maintenance) {
    return (
      <LoadingIndicator>
        <LoadingOutlined style={{ fontSize: 64, margin: 'auto' }} spin />
      </LoadingIndicator>
    )
  }

  const initialData = {
    customer: maintenance.customer,
    data_font: maintenance.data_font,
    name: maintenance.responsible.name,
    phone: maintenance.responsible.phone,
  }

  function UpdateMaintenance(data: UpdateMaintenanceProps) {
    const formatData = {
      customer: data.customer,
      data_font: data.data_font,
      responsible: {
        name: data.name,
        phone: data.phone,
      },
    }

    try {
      updateMaintenance(formatData)

      setIsEdit(false)
    } catch (err: any) {
      console.log(err)
    }
  }

  return (
    <Card
      bordered={false}
      title={translate('MaintenanceDetailsTab.EditMaintenanceDetails')}
      extra={
        <Space>
          <Button
            htmlType="submit"
            form="updateMaintenance"
            type="primary"
            id="btn_update_maintenance"
          >
            {translate('MaintenanceDetailsTab.Save')}
          </Button>
          <Button icon={<CloseOutlined />} onClick={() => setIsEdit(false)}>
            {translate('MaintenanceDetailsTab.Cancel')}
          </Button>
        </Space>
      }
      style={{
        display: 'inline-block',
        width: '100%',
      }}
    >
      <Form
        form={form}
        layout="vertical"
        id="updateMaintenance"
        initialValues={initialData}
        onFinish={UpdateMaintenance}
      >
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item
              label={
                <Space>
                  <p>{translate('MaintenanceDetailsTab.Client')}</p>

                  <ClientModalMaintenance type="edit" />
                </Space>
              }
              name="customer"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={translate('MaintenanceDetailsTab.DataSourceUpdate')}
              name="data_font"
            >
              <Input placeholder="Informe a data fonte" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={12}>
          <Col span={12}>
            <Form.Item
              label={
                <Space>
                  <p>{translate('MaintenanceDetailsTab.Employee')}</p>
                  <ContributorsMaintenanceTable type="edit" />
                </Space>
              }
              name="name"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={translate('MaintenanceDetailsTab.ResponsiblePhone')}
              name="phone"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  )
}

export default UpdateMaintenance
