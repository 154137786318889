/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-underscore-dangle */
import { ColumnsType } from 'antd/lib/table'
import React from 'react'

import {
  Popconfirm,
  Space,
  Table,
  Tooltip,
  Button,
  Tag,
  Typography,
} from 'antd'
import { DeleteFilled, LoadingOutlined } from '@ant-design/icons'

import { translate } from '~/utils/locale'
import { useSystem } from '~/hooks/Maintenance/System/useSystem'
import UpdateSystem from '../Update'

// import { Container } from './styles';

interface SystemProps {
  _id: string
  name: string
  createdAt: string
  activities: [{ activity: string }]
  components?: [{ name: string }]
  periodicity: {
    type: string
    interval: number
    date: Date
  }
}

const SystemTable: React.FC = () => {
  const { system, deleteSystem, isLoading } = useSystem()

  const remainingActivities = (activity: any) => {
    return activity.length - 1
  }

  const columns: ColumnsType<SystemProps> = [
    {
      title: translate('SystemTable.nameColumn'),
      key: 'name',
      dataIndex: 'name',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: translate('SystemTable.componentColumn'),
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (record: SystemProps) => (
        <>
          {record?.components?.length === 1 ? (
            <Typography.Text>{record?.components?.[0]?.name}</Typography.Text>
          ) : (
            <Tag color="green">{record?.components?.length} componentes</Tag>
          )}
        </>
      ),
    },
    {
      title: translate('MaintenanceTable.periodicityColumn'),
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (record: SystemProps) => (
        <>
          {record?.periodicity?.type === 'sem' ? (
            <Tag color="blue">
              {translate('MaintenanceTable.periodicityText')}{' '}
              {record.periodicity.interval}{' '}
              {translate('MaintenanceTable.weeksText')}
            </Tag>
          ) : (
            ''
          )}

          {record?.periodicity?.type === 'men' ? (
            <Tag color="blue">
              {translate('MaintenanceTable.periodicityText')}{' '}
              {record.periodicity.interval}{' '}
              {translate('MaintenanceTable.monthsText')}
            </Tag>
          ) : (
            ''
          )}

          {record?.periodicity?.type === 'anu' ? (
            <Tag color="blue">
              {translate('MaintenanceTable.periodicityText')}{' '}
              {record.periodicity.interval}{' '}
              {translate('MaintenanceTable.yearsText')}
            </Tag>
          ) : (
            ''
          )}

          {record?.periodicity?.type === 'day' ? (
            <Tag color="blue">
              {translate('MaintenanceTable.periodicityText')}{' '}
              {record.periodicity.interval}{' '}
              {translate('MaintenanceTable.daysText')}
            </Tag>
          ) : (
            ''
          )}
        </>
      ),
    },
    {
      title: translate('SystemTable.activityColumn'),
      key: 'activity',
      render: (record: SystemProps) => (
        <Space>
          <Tooltip title={record?.activities[0]?.activity}>
            {record?.activities[0]?.activity?.length > 40
              ? `${record?.activities[0]?.activity?.substring(0, 40)}...`
              : record?.activities[0]?.activity}
          </Tooltip>
          {record?.activities?.length > 1 ? (
            <Tag color="green">
              + {remainingActivities(record?.activities)}{' '}
              {translate('SystemTable.activitiesText')}
            </Tag>
          ) : (
            ''
          )}
        </Space>
      ),
    },
    {
      title: translate('TasksRegistrationPauseTable.actionsColumn'),
      align: 'center',
      key: 'remove',
      render: (record) => (
        <Space size="middle">
          <UpdateSystem data={record} />
          <Popconfirm
            title={translate('SystemTable.deleteConfirmText')}
            onConfirm={() => deleteSystem(record._id)}
          >
            <Tooltip
              title={translate('TasksRegistrationPauseTable.deleteTooltip')}
            >
              <Button ghost danger shape="circle" icon={<DeleteFilled />} />
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ]

  return (
    <>
      <Table
        rowKey={(record) => record._id}
        loading={{
          indicator: <LoadingOutlined />,
          spinning: isLoading,
          size: 'large',
        }}
        scroll={{ x: 1000 }}
        columns={columns}
        dataSource={system}
        pagination={{
          showTotal: (total, range) => `${range[0]} - ${range[1]} / ${total}`,
        }}
      />
    </>
  )
}

export default SystemTable
