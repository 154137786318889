export default {
  pt: {
    MaintenanceDetailsTab: {
      MaintenanceManagement: 'Gestão de Manutenções',
      MaintenanceDetails: 'Detalhes da Manutenção',

      ClientCondominium: 'Cliente/Condomínio:',
      ResponsibleEmployee: 'Colaborador Responsável:',
      ResponsibleEmployeePhone: 'Celular do Colaborador Responsável:',
      DataSource: 'Data Fonte:',
      SystemsCount: 'Quantidade de sistemas:',

      EditMaintenanceDetails: 'Editar Detalhes da Manutenção',

      Client: 'Cliente',
      Employee: 'Colaborador',
      DataSourceUpdate: 'Data Fonte',
      ResponsiblePhone: 'Celular do Responsável',

      Save: 'Salvar',
      Edit: 'Editar',
      Cancel: 'Cancelar',

      Details: 'Detalhes',
      Systems: 'Sistemas',
    },
  },
  en: {
    MaintenanceDetailsTab: {
      MaintenanceManagement: 'Maintenance Management',
      MaintenanceDetails: 'Maintenance Details',

      ClientCondominium: 'Client/Condominium:',
      ResponsibleEmployee: 'Responsible Employee:',
      ResponsibleEmployeePhone: 'Responsible Employee Phone:',
      DataSourceText: 'Data Source:',
      SystemsCount: 'Number of systems:',

      EditMaintenanceDetails: 'Edit Maintenance Details',

      Client: 'Client',
      Employee: 'Employee',
      DataSourceUpdate: 'Data Source',
      ResponsiblePhone: 'Responsible Phone',

      Save: 'Save',
      Edit: 'Edit',
      Cancel: 'Cancel',

      Details: 'Details',
      Systems: 'Systems',
    },
  },
  es: {
    MaintenanceDetailsTab: {
      MaintenanceManagement: 'Gestión de Mantenimientos',
      MaintenanceDetails: 'Detalles del Mantenimiento',

      ClientCondominium: 'Cliente/Condominio:',
      ResponsibleEmployee: 'Colaborador Responsable:',
      ResponsibleEmployeePhone: 'Teléfono del Colaborador Responsable:',
      DataSource: 'Fecha Fuente:',
      SystemsCount: 'Cantidad de sistemas:',

      EditMaintenanceDetails: 'Editar Detalles del Mantenimiento',

      Client: 'Cliente',
      Employee: 'Colaborador',
      DataSourceUpdate: 'Fecha Fuente',
      ResponsiblePhone: 'Teléfono del Responsable',

      Save: 'Guardar',
      Edit: 'Editar',
      Cancel: 'Cancelar',

      Details: 'Detalles',
      Systems: 'Sistemas',
    },
  },
}
